import { Grid } from "@mui/material";
import { useQuery } from "react-query";
import { useAxios } from "../../../Providers/AuthProvider";
import { Loading } from "../../../Components/Loading";
import { MessageBox } from "../../../Components/MessageBox";
import { pickTasks } from "../../../API/tasks";
import TweetLink from "../../../Components/Task/TweetLink";
import Paper from "../../../Components/Paper";

export default function PickTasks() {
  const axios = useAxios();

  const { isLoading, isError, isSuccess, data, isFetching, refetch } = useQuery(
    "pickTasks",
    async () => {
      const results = await pickTasks(1, axios);
      return results;
    }
  );

  function renderData() {
    if (!data) return;
    if (isFetching) {
      return <Loading>loading tasks</Loading>;
    }
    return (
      <>
        {data.length === 0 && isSuccess && (
          <MessageBox>No tasks found.</MessageBox>
        )}
        {data.length > 0 &&
          data.map((item) => {
            switch (item.type) {
              case "tweetLink":
                return (
                  <Grid item xs={12} key={item._id}>
                    <TweetLink task={item} finished={refetch} />
                  </Grid>
                );
              default:
                return (
                  <Grid item xs={12} key={item._id}>
                    <Paper>
                      not defined type ({item.type}) task {item._id}
                    </Paper>
                  </Grid>
                );
            }
          })}
      </>
    );
  }
  if (isLoading) {
    return <Loading>loading tasks</Loading>;
  }
  return (
    <Grid container>
      {isError && (
        <MessageBox>An error occured, please try again later.</MessageBox>
      )}
      {renderData()}
    </Grid>
  );
}
