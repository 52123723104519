import { Grid } from "@mui/material";
import Typography from "../../Typography";
import { IDocument } from "../../../Utils/Structures";

interface IProps {
  record: IDocument;
}
export default function Links({ record }: IProps) {
  return (
    <>
      <Grid item xs={12}>
        <Typography lang="en" variant="body2">
          Links:
        </Typography>
      </Grid>
      {record.links.map((item, index) => (
        <Grid item key={index} xs={12}>
          <Typography lang="en" variant="caption">
            <a href={item}>{item}</a>
          </Typography>
        </Grid>
      ))}
    </>
  );
}
