import { useContext, useState } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  Grid,
  Typography,
  Button,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import GroupIcon from "@mui/icons-material/Group";
import Tooltip from "@mui/material/Tooltip";
import {
  AuthContext,
  useIsLoggedIn,
  useIsRegulator,
} from "../Providers/AuthProvider";
import ProfileDialog from "../Components/Auth/ProfileDialog";
import DropDownMenu from "../Components/DropDownMenu/DropDownMenu";
interface IProps {
  children: React.ReactElement;
}
export default function PublicLayout({ children }: IProps) {
  const navigate = useNavigate();

  const { openLoginDialog, openLogoutDialog, openRegisterDialog, profile } =
    useContext(AuthContext);

  const [openProfile, setOpenProfile] = useState(false);

  const isLoggedIn = useIsLoggedIn();
  const isRegulator = useIsRegulator();

  function renderTasksMenu() {
    const items = [
      {
        title: "tasks report",
        onClick: () => {
          navigate("/tasks/report");
        },
      },
      {
        title: "view all tasks",
        onClick: () => {
          navigate("/tasks/search");
        },
      },
    ];
    if (isLoggedIn) {
      items.push({
        title: "pick a task",
        onClick: () => {
          navigate("/tasks/pick");
        },
      });
    }
    if (isRegulator) {
      items.push({
        title: "review a task",
        onClick: () => {
          navigate("/tasks/review");
        },
      });
    }
    return (
      <DropDownMenu items={items}>
        <PlaylistAddCheckIcon />
      </DropDownMenu>
    );
  }
  function renderContributionData() {
    const items = [
      {
        title: "contributors leaderboard",
        onClick: () => {
          navigate("/contributors/leaderboard");
        },
      },
    ];
    if (isRegulator) {
      items.push({
        title: "manage contributors",
        onClick: () => {
          navigate("/contributors/manage");
        },
      });
    }
    if (isLoggedIn) {
      items.push({
        title: "my contribution history",
        onClick: () => {
          navigate("/contributors/me");
        },
      });
    }
    return (
      <DropDownMenu items={items}>
        <GroupIcon />
      </DropDownMenu>
    );
  }

  return (
    <Grid container style={{ marginTop: "68px", marginBottom: "38px" }}>
      <Grid item xs={12}>
        <AppBar>
          <Toolbar>
            <Typography
              onClick={() => {
                navigate("/");
              }}
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              style={{ cursor: "pointer" }}
            >
              YMR OSINT
            </Typography>
            {renderTasksMenu()}
            {renderContributionData()}
            {!isLoggedIn && (
              <>
                <Button
                  color="inherit"
                  size="small"
                  onClick={openLoginDialog}
                  style={{ marginTop: 8 }}
                >
                  Login
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  onClick={openRegisterDialog}
                  style={{ marginTop: 8 }}
                >
                  Register
                </Button>
              </>
            )}
            {isLoggedIn && (
              <Tooltip title="profile">
                <IconButton
                  color="inherit"
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  <PersonIcon />
                </IconButton>
              </Tooltip>
            )}
            {isLoggedIn && (
              <Tooltip title="logout">
                <IconButton color="inherit" onClick={openLogoutDialog}>
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            )}
          </Toolbar>
        </AppBar>
      </Grid>
      {profile && (
        <ProfileDialog
          profile={profile}
          open={openProfile}
          onClose={() => {
            setOpenProfile(false);
          }}
        />
      )}
      <Grid item xs={12}>
        <Container maxWidth="lg">
          {!!profile && !profile.isActive && (
            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs={12}>
                <Alert severity="warning">
                  your user is inactive. there are several reasons may cause
                  your account being inactive:
                  <ul>
                    <li>
                      you just regisered your new account. you have to wait for
                      a regulator to approve your register sumbittion
                    </li>
                    <li>
                      you are inactive due to fraud activities done by your
                      account. please contact regulators if you think it's an
                      missunderstanding
                    </li>
                  </ul>
                  while your account is inactive, you can't submit any
                  contribution to the YMR project.
                </Alert>
              </Grid>
            </Grid>
          )}
          {children}
        </Container>
      </Grid>
    </Grid>
  );
}
