import { Grid, Alert, Button } from "@mui/material";
import RequestCard from "../../../Components/RequestCard/RequestCard";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { useQuery } from "react-query";
import { useAxios, useIsRegulator } from "../../../Providers/AuthProvider";
import { Loading } from "../../../Components/Loading";
import { MessageBox } from "../../../Components/MessageBox";
import { searchRequests } from "../../../API/requests";
import { pageSize } from "../../../config";
import { useState } from "react";
import Pagination from "../../../Components/Pagination";
import AddRequestDialog from "../../../Components/RequestDialogs/AddRequestDialog";

interface IProps {
  caseId: string;
}
export default function RequestsTab({ caseId }: IProps) {
  const axios = useAxios();
  const isRegulator = useIsRegulator();
  const [showAddCase, setShowAddCase] = useState(false);
  const [page, setPage] = useState(1);
  const { isLoading, isError, data, isSuccess, refetch, isRefetching } =
    useQuery(["fetchCaseRequests", caseId], async () => {
      const result = await searchRequests({ caseId, page, pageSize }, axios);
      console.log(result);
      return result;
    });
  if (isError) {
    <MessageBox>An error occured, please try again later.</MessageBox>;
  }
  if (isRefetching || isLoading || !data) {
    return <Loading>loading requests</Loading>;
  }
  return (
    <Grid container>
      {isRegulator && (
        <AddRequestDialog
          open={showAddCase}
          onClose={(r) => {
            if (r) {
              refetch();
            }
            setShowAddCase(false);
          }}
          caseId={caseId}
        />
      )}
      {isRegulator && (
        <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
          <Button
            variant="contained"
            onClick={() => {
              setShowAddCase(true);
            }}
          >
            Add Request
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <Alert severity="info">
          Please check information and data requests on this case. if you know
          anything about this case please submit it by clicking on{" "}
          <ChatBubbleOutlineIcon
            fontSize="small"
            style={{ marginLeft: 5, marginBottom: -5 }}
          />
        </Alert>
      </Grid>
      {data.meta.count === 0 && isSuccess && (
        <MessageBox>No requests found.</MessageBox>
      )}
      {data.meta.count > 0 &&
        data.data.map((item) => (
          <Grid item xs={12} key={item._id}>
            <RequestCard record={item} />
          </Grid>
        ))}
      {data.meta.count > pageSize && (
        <Grid item xs={12}>
          <Pagination
            page={page}
            onChange={(value) => {
              setPage(value);
              setTimeout(() => {
                refetch();
              }, 0);
            }}
            count={Math.ceil(data.meta.count / pageSize)}
          />
        </Grid>
      )}
    </Grid>
  );
}
