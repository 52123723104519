import { Divider, Grid } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { IDocument } from "../../../Utils/Structures";

interface IProps {
  record: IDocument;
}
export default function Description({ record }: IProps) {
  return (
    <>
      {record.description.fa !== "N.A" && (
        <Grid item xs={12} style={{ direction: "rtl", textAlign: "right" }}>
          <ReactMarkdown>{record.description.fa}</ReactMarkdown>
        </Grid>
      )}
      {record.description.fa !== "N.A" && record.description.en !== "N.A" && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
      {record.description.en !== "N.A" && (
        <Grid item xs={12} style={{ direction: "ltr", textAlign: "left" }}>
          <ReactMarkdown>{record.description.en}</ReactMarkdown>
        </Grid>
      )}
    </>
  );
}
