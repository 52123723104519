import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { useContext, useState } from "react";
import { AuthContext, useAxios } from "../../Providers/AuthProvider";
import { ToastContext } from "../../Providers/ToastProvider";
import { logout } from "../../API/auth";
import { isAuthError } from "../../API/axios";
import { AxiosError } from "axios";

interface IProps {
  open: boolean;
  onClose: () => void;
}
export default function LogoutDialog({ open, onClose }: IProps) {
  const axios = useAxios();
  const { unauthorize } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);

  const [submitting, setSubmitting] = useState(false);
  async function submit() {
    try {
      setSubmitting(true);
      await logout(axios);
      unauthorize();
      showToast("logged out from successfully");
      onClose();
    } catch (error) {
      if (isAuthError(error as AxiosError)) {
        unauthorize();
        showToast("logged out locally");
        onClose();
      }
    } finally {
      setSubmitting(false);
    }
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Logout</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1">
              Are you sure you want to logout from your account?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={submit} variant="contained" style={{ minWidth: 80 }}>
          {submitting ? (
            <CircularProgress color="inherit" size="24.5px" />
          ) : (
            "Logout"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
