import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { useContext, useState } from "react";
import { useAxios } from "../../Providers/AuthProvider";
import { ToastContext } from "../../Providers/ToastProvider";
import { Typography } from "@mui/material";
import { IRequest } from "../../Utils/Structures";
import { removeRequest } from "../../API/requests";
import { useQueryClient } from "react-query";

interface IProps {
  record: IRequest;
  open: boolean;
  onClose: (refetch: boolean) => void;
}
export default function RemoveRequestDialog({ record, open, onClose }: IProps) {
  const queryClient = useQueryClient();
  const axios = useAxios();
  const { showToast } = useContext(ToastContext);
  const [error, setError] = useState("");

  const [submitting, setSubmitting] = useState(false);
  async function submit() {
    try {
      setSubmitting(true);
      await removeRequest(record._id, axios);
      queryClient.refetchQueries("searchRequests");
      queryClient.refetchQueries("fetchCaseRequests");
      showToast("request removed");
      onClose(true);
    } catch (error: any) {
      console.log(error);
      setError("there is an internal error, please try again later");
    } finally {
      setSubmitting(false);
    }
  }
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Remove Request</DialogTitle>
      <DialogContent>
        <Grid container style={{ marginTop: 0 }}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Are you sure you want to delete request {record.title.en} -{" "}
              {record.title.fa}?
            </Typography>
          </Grid>
          {!!error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            onClose(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={submit}
          variant="contained"
          color="error"
          style={{ minWidth: 80 }}
        >
          {submitting ? (
            <CircularProgress color="inherit" size="24.5px" />
          ) : (
            "Delete"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
