import { Grid, Typography, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DehazeIcon from "@mui/icons-material/Dehaze";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import Paper from "../Paper";
import { IRequest } from "../../Utils/Structures";
import { useState } from "react";
import UpdateRequestDialog from "../RequestDialogs/UpdateRequestDialog";
import RemoveRequestDialog from "../RequestDialogs/RemoveRequestDialog";
import { useNavigate } from "react-router-dom";
import { useIsRegulator } from "../../Providers/AuthProvider";

interface IProps {
  record: IRequest;
  navigateToCase?: boolean;
}
export default function RequestCard({
  record,
  navigateToCase = false,
}: IProps) {
  const [showEdit, setShowEdit] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const navigate = useNavigate();
  const isRegulator = useIsRegulator();
  return (
    <Paper>
      {isRegulator && (
        <>
          <UpdateRequestDialog
            open={showEdit}
            onClose={() => {
              setShowEdit(false);
            }}
            record={record}
          />
          <RemoveRequestDialog
            open={showRemove}
            onClose={() => {
              setShowRemove(false);
            }}
            record={record}
          />
        </>
      )}
      <Grid container>
        <Grid item xs={12} style={{ display: "flex" }}>
          <Typography variant="body1" style={{ marginRight: "auto" }}>
            {record.title.en}
          </Typography>
          {navigateToCase && (
            <Tooltip title="show case">
              <IconButton
                style={{ marginLeft: "auto", marginTop: -5 }}
                onClick={() => {
                  navigate("/case/" + record.case._id);
                }}
              >
                <DehazeIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="submit data">
            <IconButton style={{ marginLeft: 5, marginTop: -5 }}>
              <ChatBubbleOutlineIcon />
            </IconButton>
          </Tooltip>
          {isRegulator && (
            <Tooltip title="edit request info">
              <IconButton
                style={{ marginLeft: 5, marginTop: -5 }}
                onClick={() => {
                  setShowEdit(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {isRegulator && (
            <Tooltip title="remove this request">
              <IconButton
                style={{ marginLeft: 5, marginTop: -5 }}
                onClick={() => {
                  setShowRemove(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={12} style={{ display: "flex" }}>
          <Typography variant="body2">{record.description.en}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
