import Paper from "../../../../Components/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Button, Typography } from "@mui/material";

interface IProps {
  fullName: string;
  setFullName: (value: string) => void;
  onSearch: () => void;
}
export default function SearchForm({
  fullName,
  setFullName,
  onSearch,
}: IProps) {
  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">Search in Reqests</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={fullName}
            onChange={(event) => {
              setFullName(event.target.value as string);
            }}
            label="case full name"
            variant="outlined"
            placeholder="full name or part of it"
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={onSearch}>
            Search
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
