import Paper from "../../../../Components/Paper";
import { Grid, Typography, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tag from "../../../../Components/Tag";
import { ICase } from "../../../../Utils/Structures";
import { getTagTitle } from "../../../../Consts/utils";
import { useState } from "react";
import { useIsRegulator } from "../../../../Providers/AuthProvider";
import UpdateCaseDialog from "../../../../Components/CaseDialogs/UpdateCaseDialog";
import RemoveCaseDialog from "../../../../Components/CaseDialogs/RemoveCaseDialog";

interface IProps {
  record: ICase;
  onUpdate: () => void;
  onDelete: () => void;
}
export default function InfoCard({ record, onUpdate, onDelete }: IProps) {
  const isRegulator = useIsRegulator();
  const [showEdit, setShowEdit] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  return (
    <Paper>
      <Grid container>
        <Grid item xs={12} style={{ display: "flex" }}>
          <Typography variant="body1">{record.title.en}</Typography>
          {isRegulator && (
            <>
              <Tooltip title="edit case info">
                <IconButton
                  style={{ marginLeft: 5, marginTop: -5 }}
                  onClick={() => {
                    setShowEdit(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="remove this case">
                <IconButton
                  style={{ marginLeft: 5, marginTop: -5 }}
                  onClick={() => {
                    setShowRemove(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">
            Verification State: {record.state}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">{record.description.en}</Typography>
        </Grid>
        <Grid item xs={12}>
          {record.tags.map((item) => (
            <Tag key={item}>{getTagTitle(item, "en")}</Tag>
          ))}
        </Grid>
      </Grid>
      <UpdateCaseDialog
        record={record}
        open={showEdit}
        onClose={(refetch) => {
          setShowEdit(false);
          if (refetch) {
            onUpdate();
          }
        }}
      />
      <RemoveCaseDialog
        record={record}
        open={showRemove}
        onClose={(refetch) => {
          setShowRemove(false);
          if (refetch) {
            onDelete();
          }
        }}
      />
    </Paper>
  );
}
