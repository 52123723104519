import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { ITask } from "../../../../Utils/Structures";
import { tsToTimeDate } from "../../../../Utils/time";
import TweetLinkDetails from "./TweetLinkDetails";

interface IProps {
  task: ITask;
  open: boolean;
  onClose: () => void;
}
export default function TaskDetails({ task, open, onClose }: IProps) {
  function details() {
    switch (task.type) {
      case "tweetLink":
        return <TweetLinkDetails task={task} />;
      default:
        return task.type;
    }
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>Details</DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="caption">
              task created at {tsToTimeDate(task.createdAt)} and updated at
              {tsToTimeDate(task.updatedAt)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {details()}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
