import { Grid, Typography } from "@mui/material";
import { Tweet } from "react-twitter-widgets";
import { ITask } from "../../../../Utils/Structures";

interface IProps {
  task: ITask;
}
export default function TweetLink({ task }: IProps) {
  if (!task.source) return null;

  const tweetId = (task.source || "").split("/")[5];
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Tweet tweetId={tweetId} options={{ conversation: "none" }} />
      </Grid>
      <Grid item>
        <ul>
          <li>
            <Typography variant="caption">
              task was assigned to <b>{task.assignee?.name}</b>
            </Typography>
          </li>
          <li>
            <Typography variant="caption">
              contributor decided the link is <b>{task.result.acceptState}</b>
            </Typography>
          </li>
        </ul>
      </Grid>
    </Grid>
  );
}
