import { Axios } from "axios";
import { IDocument, ICollection } from "../Utils/Structures";
import { IAuthResponse } from "./auth";

interface ISearchDocumentParams {
  contributorId?: string;
  term?: string;
  caseId?: string;
  state?: string;
  states?: string[];
  types?: string[];
  page?: number;
  pageSize?: number;
}
export function searchDocuments(params: ISearchDocumentParams, axios: Axios) {
  return axios
    .post<ICollection<IDocument>>("/documents-search", params)
    .then((response) => response.data);
}

interface IExtraField {
  lng?: number;
  lat?: number;
  country?: string;
  province?: string;
  city?: string;

  age?: number;
  birth_date?: string;
  honor_date?: string;

  file?: File;
}

function addExtraFields(formData: FormData, values: IExtraField) {
  if (values.lng) formData.append("lng", values.lng + "");
  if (values.lat) formData.append("lat", values.lat + "");
  if (values.country) formData.append("country", values.country + "");
  if (values.province) formData.append("province", values.province + "");
  if (values.age) formData.append("age", values.age + "");
  if (values.birth_date) formData.append("birth_date", values.birth_date + "");
  if (values.honor_date) formData.append("honor_date", values.honor_date + "");
  if (values.file) formData.append("file", values.file);
}

/**
 * regulator apis
 */
interface IDocumentDataRegulatorParams extends IExtraField {
  description_fa: string;
  description_en: string;
  cases: string[];
  contributors: string[];
  type: string;
  state: string;
  source: string;
}

export function createDocumentRegulator(
  params: IDocumentDataRegulatorParams,
  axios: Axios
) {
  const formData = new FormData();
  if (params.description_fa)
    formData.append("description_fa", params.description_fa);
  if (params.description_en)
    formData.append("description_en", params.description_en);
  if (params.type) formData.append("type", params.type);
  if (params.state) formData.append("state", params.state);
  if (params.source) formData.append("source", params.source);
  addExtraFields(formData, params);

  if (params.cases) {
    for (let i = 0; i < params.cases.length; i++) {
      formData.append("cases:" + i, params.cases[i]);
    }
  }
  if (params.contributors) {
    for (let i = 0; i < params.contributors.length; i++) {
      formData.append("contributors:" + i, params.contributors[i]);
    }
  }
  return axios
    .post<IDocument>("/documents-createRegulator", formData)
    .then((response) => response.data);
}

export function updateDocumentRegulator(
  id: string,
  params: IDocumentDataRegulatorParams,
  axios: Axios
) {
  const formData = new FormData();
  formData.append("id", id);
  if (params.description_fa)
    formData.append("description_fa", params.description_fa);
  if (params.description_en)
    formData.append("description_en", params.description_en);
  if (params.type) formData.append("type", params.type);
  if (params.state) formData.append("state", params.state);
  if (params.source) formData.append("source", params.source);
  addExtraFields(formData, params);

  if (params.cases) {
    for (let i = 0; i < params.cases.length; i++) {
      formData.append("cases:" + i, params.cases[i]);
    }
  }
  if (params.contributors) {
    for (let i = 0; i < params.contributors.length; i++) {
      formData.append("contributors:" + i, params.contributors[i]);
    }
  }
  return axios
    .post<IDocument>("/documents-updateRegulator", formData)
    .then((response) => response.data);
}

/**
 * contributor apis
 */
interface IDocumentDataContributorParams extends IExtraField {
  description_fa: string;
  description_en: string;
  cases: string[];
  type: string;
  source: string;
}

export function createDocumentContribotur(
  params: IDocumentDataContributorParams,
  axios: Axios
) {
  const formData = new FormData();
  if (params.description_fa)
    formData.append("description_fa", params.description_fa);
  if (params.description_en)
    formData.append("description_en", params.description_en);
  if (params.type) formData.append("type", params.type);
  if (params.source) formData.append("source", params.source);
  addExtraFields(formData, params);

  if (params.cases) {
    for (let i = 0; i < params.cases.length; i++) {
      formData.append("cases:" + i, params.cases[i]);
    }
  }
  return axios
    .post<IDocument>("/documents-createContributor", formData)
    .then((response) => response.data);
}

export function reviewDocument(
  id: string,
  params: IDocumentDataContributorParams,
  axios: Axios
) {
  const formData = new FormData();
  formData.append("id", id);
  if (params.description_fa)
    formData.append("description_fa", params.description_fa);
  if (params.description_en)
    formData.append("description_en", params.description_en);
  if (params.type) formData.append("type", params.type);
  if (params.source) formData.append("source", params.source);
  addExtraFields(formData, params);

  if (params.cases) {
    for (let i = 0; i < params.cases.length; i++) {
      formData.append("cases:" + i, params.cases[i]);
    }
  }
  return axios
    .post<IDocument>("/documents-review", formData)
    .then((response) => response.data);
}

/**
 * guest apis
 */
interface IDocumentDataGuestParams extends IExtraField {
  description_fa: string;
  description_en: string;
  cases: string[];
  type: string;
  source: string;

  username: string;
  password: string;
  nickname: string;
}

export function createDocumentGuest(
  params: IDocumentDataGuestParams,
  axios: Axios
) {
  const formData = new FormData();
  if (params.description_fa)
    formData.append("description_fa", params.description_fa);
  if (params.description_en)
    formData.append("description_en", params.description_en);
  if (params.type) formData.append("type", params.type);
  if (params.source) formData.append("source", params.source);
  addExtraFields(formData, params);
  if (params.username) formData.append("username", params.username + "");
  if (params.password) formData.append("password", params.password + "");
  if (params.nickname) formData.append("nickname", params.nickname + "");

  if (params.cases) {
    for (let i = 0; i < params.cases.length; i++) {
      formData.append("cases:" + i, params.cases[i]);
    }
  }
  return axios
    .post<{ document: IDocument; user: IAuthResponse }>(
      "/documents-createGuest",
      formData
    )
    .then((response) => response.data);
}

export function updateDocument(
  id: string,
  params: IDocumentDataRegulatorParams,
  axios: Axios
) {
  return axios
    .post<IDocument>("/documents-update", { ...params, id })
    .then((response) => response.data);
}

export function removeDocument(id: string, axios: Axios) {
  return axios
    .post("/documents-remove", { id })
    .then((response) => response.data);
}

export function getDocument(id: string, axios: Axios): Promise<IDocument> {
  return axios
    .post<IDocument>("/documents-get", { id })
    .then((response) => response.data);
}
