import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { searchTasks } from "../../../API/tasks";
import { Loading } from "../../../Components/Loading";
import { MessageBox } from "../../../Components/MessageBox";
import Pagination from "../../../Components/Pagination";
import { useAxios } from "../../../Providers/AuthProvider";
import { tsToTimeDate } from "../../../Utils/time";

const pageSize = 15;
interface IProps {
  id: string;
}
export default function PicketTasksTab({ id }: IProps) {
  const axios = useAxios();
  const [page, setPage] = useState(1);
  const { isLoading, isFetching, data } = useQuery(
    ["self-pciked-tasks", page],
    async () => {
      try {
        const results = await searchTasks(
          { page, pageSize, assigneeId: id, state: "picked" },
          axios
        );
        return results;
      } catch (e) {
        console.log(e);
      }
    }
  );

  if (isLoading || isFetching || !data) {
    return <Loading>loading tasks</Loading>;
  }
  const records = data.data;
  const count = data.meta.count;
  const pageCount = Math.ceil(count / pageSize);
  if (count === 0) {
    return <MessageBox>there are no tasks</MessageBox>;
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>type</TableCell>
              <TableCell>created at</TableCell>
              <TableCell>updated at</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record, index) => (
              <TableRow key={record._id}>
                <TableCell>{(page - 1) * 15 + index + 1}</TableCell>
                <TableCell>{record.type}</TableCell>
                <TableCell>{tsToTimeDate(record.createdAt)}</TableCell>
                <TableCell>{tsToTimeDate(record.updatedAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      {count > pageSize && (
        <Grid item xs={12}>
          <Pagination count={pageCount} page={page} onChange={setPage} />
        </Grid>
      )}
    </Grid>
  );
}
