import { Grid, Tabs, Tab } from "@mui/material";
import { useState } from "react";
import InfoCard from "./Components/InfoCard/InfoCard";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getCase } from "../../API/cases";
import { useAxios } from "../../Providers/AuthProvider";
import { Loading } from "../../Components/Loading";
import { MessageBox } from "../../Components/MessageBox";
import RequestsTab from "./Components/RequestsTab";
import ApprovedDocumentsTab from "./Components/ApprovedDocumentsTab";
import PendingDocumentsTab from "./Components/PendingDocumentsTab";
import DeclinedDocumentsTab from "./Components/DeclinedDocumentsTab";
export default function Case() {
  const axios = useAxios();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [activeTab, setActiveTab] = useState(0);
  const {
    isLoading,
    isError,
    data: record,
    refetch,
  } = useQuery(["fetchCase", id], async () => {
    if (!id) return;
    const result = await getCase(id, axios);
    return result;
  });
  if (isError) {
    <MessageBox>An error occured, please try again later.</MessageBox>;
  }
  if (isLoading || !record || !id) {
    return <Loading>loading case</Loading>;
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <InfoCard
          record={record}
          onUpdate={refetch}
          onDelete={() => {
            navigate("/");
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={activeTab}
          onChange={(_, value) => {
            setActiveTab(value);
          }}
        >
          <Tab label="Documents (approved)" />
          <Tab label="Documents (pending)" />
          <Tab label="Documents (declined)" />
          <Tab label="Requests" />
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        {activeTab === 0 && <ApprovedDocumentsTab caseId={id} />}
        {activeTab === 1 && <PendingDocumentsTab caseId={id} />}
        {activeTab === 2 && <DeclinedDocumentsTab caseId={id} />}
        {activeTab === 3 && <RequestsTab caseId={id} />}
      </Grid>
    </Grid>
  );
}
