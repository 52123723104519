import Paper from "../../../../Components/Paper";
import Grid from "@mui/material/Grid";
import { CircularProgress, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { DocumentState, DocumentType } from "../../../../Utils/Structures";
import { useFormik } from "formik";
import TextInput from "../../../../Components/TextInput/TextInput";

interface IProps {
  searching: boolean;
  onSearch: (data: {
    term: string;
    states: DocumentState[];
    types: DocumentType[];
  }) => void;
}
export default function SearchForm({ onSearch, searching }: IProps) {
  const formik = useFormik({
    initialValues: {
      term: "",
      types: [],
      states: [],
    },
    onSubmit: async (values) => {
      onSearch(values);
      // try {
      //   const user = await updateProfile(values, axios);
      //   updateLocalProfile(user);
      //   showToast("document decilined");
      //   onClose();
      // } catch (error: any) {
      //   console.log(error);
      //   showToast("there is an internal error, please try again later");
      // }
    },
  });
  return (
    <Paper>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Search in Documents</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Term"
              placeholder="names, places, dates, ..."
              value={formik.values.term}
              onChange={formik.handleChange}
              name="term"
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel>types</InputLabel>
              <Select
                value={types}
                size="small"
                onChange={(event) => {
                  const value = event.target.value as DocumentType[];
                  setTypes(value);
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        size="small"
                        key={value}
                        label={getDocumentTypeTitle(value, "en")}
                      />
                    ))}
                  </Box>
                )}
                input={<OutlinedInput label="Name" />}
                multiple
              >
                {DTypes.map((item) => (
                  <MenuItem value={item.slug} key={item.slug}>
                    {item.titles.en}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel>states</InputLabel>
              <Select
                value={states}
                size="small"
                onChange={(event) => {
                  const value = event.target.value as DocumentState[];
                  setStates(value);
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        size="small"
                        key={value}
                        label={getDocumentStateTitle(value, "en")}
                      />
                    ))}
                  </Box>
                )}
                input={<OutlinedInput label="Name" />}
                multiple
              >
                {DStates.map((state) => (
                  <MenuItem value={state.slug} key={state.slug}>
                    {state.titles.en}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={12}>
            <Button variant="contained" type="submit" style={{ minWidth: 120 }}>
              {searching ? (
                <CircularProgress color="inherit" size="24.5px" />
              ) : (
                "Search"
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
