import { Grid } from "@mui/material";
import { IDocument } from "../../../Utils/Structures";

interface IProps {
  record: IDocument;
}
export default function Attachments({ record }: IProps) {
  const { attachments } = record;
  let md = 12;
  if (attachments.length === 2) {
    md = 6;
  } else if (attachments.length === 3) {
    md = 4;
  } else if (attachments.length > 3) {
    md = 3;
  }
  return (
    <>
      {attachments.map((attachment, index) => {
        if (attachment.type === "image")
          return (
            <Grid
              item
              xs={12}
              md={md}
              style={{ textAlign: "center" }}
              key={index}
            >
              <img
                style={{ maxWidth: "100%", maxHeight: "400px" }}
                src={attachment.url}
                alt="evidence"
              />
            </Grid>
          );
        else if (attachment.type === "video") {
          return (
            <Grid item xs={12} key={index} style={{ textAlign: "center" }}>
              <video controls style={{ maxWidth: "100%", maxHeight: "400px" }}>
                <source src={attachment.url} />
              </video>
            </Grid>
          );
        } else return null;
      })}
    </>
  );
}
