import { Chip, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { IUser } from "../../../../Utils/Structures";
import { useState } from "react";
import ActivateModal from "./ActivateModal";
import InActivateModal from "./InActivateModal";
import { useNavigate } from "react-router-dom";

interface IProps {
  user: IUser;
  refetch: () => void;
}
export default function UserRow({ user, refetch }: IProps) {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  return (
    <TableRow key={user._id}>
      <TableCell>{user.nickname}</TableCell>
      <TableCell>{user.username}</TableCell>
      <TableCell>
        {user.isActive ? (
          <Chip
            color="success"
            variant="filled"
            label="Active"
            size="small"
            style={{ paddingTop: 3, borderRadius: 5, minWidth: 65 }}
          />
        ) : (
          <Chip
            color="warning"
            variant="filled"
            label="InActive"
            size="small"
            style={{ paddingTop: 3, borderRadius: 5, minWidth: 65 }}
          />
        )}
      </TableCell>
      <TableCell>{user.contributionPoints}</TableCell>
      <TableCell>
        <Tooltip title="view details">
          <IconButton
            onClick={() => {
              navigate("/contributor/" + user._id);
            }}
          >
            <InfoIcon />
          </IconButton>
        </Tooltip>
        {user.isActive ? (
          <Tooltip title="inactive this user">
            <IconButton
              size="small"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="activate this user">
            <IconButton
              size="small"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <CheckIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
      {user.isActive ? (
        <InActivateModal
          refetch={refetch}
          user={user}
          onClose={() => {
            setOpenModal(false);
          }}
          show={openModal}
        />
      ) : (
        <ActivateModal
          refetch={refetch}
          user={user}
          onClose={() => {
            setOpenModal(false);
          }}
          show={openModal}
        />
      )}
    </TableRow>
  );
}
