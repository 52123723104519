import { Grid, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { getTasksReport } from "../../../API/tasks";
import { Loading } from "../../../Components/Loading";
import { Chart } from "react-google-charts";
import { useAxios } from "../../../Providers/AuthProvider";
import Paper from "../../../Components/Paper";

export default function TasksReport() {
  const axios = useAxios();
  const { isLoading, data } = useQuery("tasks-report", () => {
    return getTasksReport(axios);
  });
  if (isLoading || !data) {
    return <Loading>loading reports</Loading>;
  }
  const dataByState = [
    ["Task", "Count"],
    ["Pending", data.byState.pending],
    ["Picked up", data.byState.picked],
    ["Review", data.byState.review],
    ["Submitted", data.byState.submitted],
    ["Rejected", data.byState.rejected],
    ["Done", data.byState.done],
  ];

  const dataByType = [
    ["Task", "Count"],
    ["Verify link", data.byType.tweetLink],
    ["Translate content", data.byType.translate],
    ["Extract name", data.byType.extractName],
    ["Extract date", data.byType.extractDate],
    ["Extract location", data.byType.extractLocation],
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper>
          <Grid container>
            <Grid item xs={12}>
              <ul>
                <li>
                  <Typography variant="body1">
                    Currently there are {data.total} active tasks in OSINT
                    repository
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    tasks with "Done" state will be removed from the project
                    after 30 days.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    tasks with "Rejected" state will be restored to "Pending"
                    state after 30 days
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    tasks with "Picked up" state will be restored to "Pending"
                    state after 1 hour
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    tasks with "Submitted" state will be commited to OSINT
                    repository and labled by "Done" state after 1 hour
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} md={6}>
              <Chart
                chartType="PieChart"
                data={dataByState}
                options={{ title: "active tasks by states" }}
                width={"100%"}
                height={"400px"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Chart
                chartType="PieChart"
                data={dataByType}
                options={{ title: "active tasks by type" }}
                width={"100%"}
                height={"400px"}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
