import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { login } from "../../API/auth";
import { isAuthError } from "../../API/axios";
import { useContext, useState } from "react";
import { AuthContext, useAxios } from "../../Providers/AuthProvider";
import { ToastContext } from "../../Providers/ToastProvider";

const validationSchema = yup.object({
  username: yup.string().required(),
  password: yup.string().required(),
});

interface IProps {
  open: boolean;
  onClose: () => void;
}
export default function LoginDialog({ open, onClose }: IProps) {
  const axios = useAxios();
  const { authorize } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const [error, setError] = useState("");
  const formik = useFormik({
    initialValues: { username: "", password: "" },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setError("");
        const result = await login(values.username, values.password, axios);
        authorize(
          {
            _id: result._id,
            nickname: result.nickname,
            username: result.username,
            contributionPoints: result.contributionPoints,
            isActive: result.isActive,
            role: result.role,
            createdAt: result.createdAt,
            updatedAt: result.updatedAt,
          },
          result.accessToken,
          result.expiresAt
        );
        showToast("welcome " + result.nickname);
        onClose();
      } catch (error: any) {
        if (isAuthError(error as Error)) {
          setError("username and password are invalid");
        } else {
          console.log(error);
          setError("there is an internal error, please try again later");
        }
      }
    },
  });
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <Grid container marginTop={0}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="username"
                name="username"
                size="small"
                placeholder="your username"
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
                value={formik.values.username}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="password"
                size="small"
                name="password"
                type="password"
                placeholder="your password"
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                value={formik.values.password}
                onChange={formik.handleChange}
              />
            </Grid>
            {!!error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" style={{ minWidth: 80 }}>
            {formik.isSubmitting ? (
              <CircularProgress color="inherit" size="24.5px" />
            ) : (
              "Login"
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
