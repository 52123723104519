import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import TextInput from "../TextInput/TextInput";
import { register } from "../../API/auth";
import { AuthContext, useAxios } from "../../Providers/AuthProvider";
import { useContext } from "react";
import { ToastContext } from "../../Providers/ToastProvider";

const validationSchema = yup.object({
  nickname: yup.string().required(),
  username: yup.string().required(),
  password: yup.string().required(),
});

interface IProps {
  open: boolean;
  onClose: () => void;
}
export default function RegisterDialog({ open, onClose }: IProps) {
  const axios = useAxios();
  const { authorize } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const formik = useFormik({
    initialValues: { username: "", nickname: "", password: "" },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const result = await register(values, axios);
        authorize(
          {
            _id: result._id,
            nickname: result.nickname,
            username: result.username,
            contributionPoints: result.contributionPoints,
            isActive: result.isActive,
            role: result.role,
            createdAt: result.createdAt,
            updatedAt: result.updatedAt,
          },
          result.accessToken,
          result.expiresAt
        );
        showToast("welcome " + result.nickname);
        onClose();
      } catch (error) {
        console.log(error);
      }
    },
  });
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Register</DialogTitle>
        <DialogContent>
          <Grid container style={{ marginTop: 0 }}>
            <Grid item xs={12}>
              <TextInput
                label="username"
                name="username"
                placeholder="your username"
                error={formik.errors.username}
                value={formik.values.username}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Alert severity="info">
                your username is a private field for you to login to your
                account. please keep it safe and use an untracable username. we
                try our best to keep it safe but don't trust us.
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="nickname"
                name="nickname"
                placeholder="your nickname"
                error={formik.errors.nickname}
                value={formik.values.nickname}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Alert severity="info">
                your nickname is your public name and everybody can see it. we
                suggest you to choose an anonymouse nickname if you care for
                your privacy.
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="password"
                size="small"
                name="password"
                type="password"
                placeholder="your password"
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                value={formik.values.password}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" style={{ minWidth: 80 }}>
            {formik.isSubmitting ? (
              <CircularProgress color="inherit" size="24.5px" />
            ) : (
              "Register"
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
