import { Axios } from "axios";
import { IRequest, ICollection } from "../Utils/Structures";

interface ISearchRequestParams {
  fullName?: string;
  caseId?: string;
  page?: number;
  pageSize?: number;
}
export function searchRequests(params: ISearchRequestParams, axios: Axios) {
  return axios
    .post<ICollection<IRequest>>("/requests-search", params)
    .then((response) => response.data);
}

interface IRequestDataParams {
  title_fa: string;
  title_en: string;
  description_fa: string;
  description_en: string;
  caseId: string;
}

export function createRequest(params: IRequestDataParams, axios: Axios) {
  return axios
    .post<IRequest>("/requests-create", params)
    .then((response) => response.data);
}

export function editRequest(
  id: string,
  params: IRequestDataParams,
  axios: Axios
) {
  return axios
    .post<IRequest>("/requests-update", { ...params, id })
    .then((response) => response.data);
}

export function removeRequest(id: string, axios: Axios) {
  return axios
    .post("/requests-remove", { id })
    .then((response) => response.data);
}

export function getRequest(id: string, axios: Axios): Promise<IRequest> {
  return axios
    .post<IRequest>("/requests-get", { id })
    .then((response) => response.data);
}
