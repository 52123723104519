import { TextField } from "@mui/material";
import { ChangeEvent } from "react";

interface IProps {
  label: string;
  placeholder: string;
  rtl?: boolean;
  value?: string | number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  name: string;
  textarea?: boolean;
  type?: "text" | "password" | "number";
}
export default function TextInput(props: IProps) {
  return (
    <TextField
      fullWidth
      label={props.label}
      name={props.name}
      size="small"
      style={props.rtl ? { direction: "rtl", textAlign: "right" } : {}}
      placeholder={props.placeholder}
      error={!!props.error}
      type={props.type || "text"}
      multiline={props.textarea}
      rows={props.textarea ? 6 : undefined}
      helperText={props.error}
      value={props.value}
      onChange={props.onChange}
    />
  );
}
