import { Grid, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IDocument } from "../../../Utils/Structures";
import { tsToStr } from "../../../functions";
import React, { useRef, useState } from "react";

interface IProps {
  record: IDocument;
}

export default function Header({ record }: IProps) {
  const btnRef = useRef<HTMLButtonElement>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  return (
    <Grid item xs={12} style={{ display: "flex" }}>
      <Typography variant="caption" style={{ marginRight: "auto" }}>
        {record.type} document created at {tsToStr(record.createdAt)}
      </Typography>
      <IconButton
        size="small"
        ref={btnRef}
        onClick={() => {
          setMenuIsOpen(!menuIsOpen);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={menuIsOpen}
        onClose={() => {
          setMenuIsOpen(false);
        }}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        anchorEl={btnRef.current}
      >
        <MenuItem>Open source of this document</MenuItem>
      </Menu>
    </Grid>
  );
}
