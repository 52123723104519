import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface IProps {
  name: string;
  label: string;
  value: string;
  onChange: (event: SelectChangeEvent<any>) => void;
  error?: string;
  options: { label: string; value: string }[];
}
export default function SelectInput({
  name,
  value,
  label,
  onChange,
  error,
  options,
}: IProps) {
  return (
    <FormControl size="small" fullWidth error={!!error}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        size="small"
        label={label}
        labelId={`${name}-label`}
        name={name}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
      >
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}
