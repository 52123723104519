import { Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { reviewTask } from "../../../../API/tasks";
import Paper from "../../../../Components/Paper";
import { useAxios } from "../../../../Providers/AuthProvider";
import { ITask } from "../../../../Utils/Structures";

interface IProps {
  task: ITask;
}
export default function ApprovalCard({ task }: IProps) {
  const axios = useAxios();
  const [submitting, setSubmitting] = useState(false);
  const queryClient = useQueryClient();
  async function submit(result: "submitted" | "rejected") {
    try {
      setSubmitting(true);
      await reviewTask({ id: task._id, result }, axios);
      queryClient.refetchQueries("review-task");
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitting(false);
    }
  }
  return (
    <Grid item xs={12}>
      <Paper>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body2">do you approve this task?</Typography>
          </Grid>
          <Grid item xs={12}>
            <ul>
              <li>
                <Typography variant="body2">
                  if you approve this task. it'll be pending to be committed in
                  repo by <b>the pink rabbit</b>.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  it'll take 24 hours for the pink rabbit to commit it into the
                  repo.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  this task will stay in task pool for 30 days after commit. to
                  review it by public and make sure it was not wrong.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  please note that reversing a commit in repo is hard for the
                  pink rabbit. make sure your decision is correct.
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              style={{ minWidth: 180 }}
              disabled={submitting}
              size="small"
              color="success"
              onClick={() => {
                submit("submitted");
              }}
            >
              I Approve this task
            </Button>
            <Button
              variant="contained"
              style={{ minWidth: 180 }}
              disabled={submitting}
              color="error"
              size="small"
              onClick={() => {
                submit("rejected");
              }}
            >
              I Reject this task
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
