import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import tags from "../../Consts/tags.json";
import states from "../../Consts/case-states.json";
import { useContext, useState } from "react";
import { useAxios } from "../../Providers/AuthProvider";
import { ToastContext } from "../../Providers/ToastProvider";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { getCaseStateTitle, getTagTitle } from "../../Consts/utils";
import { editCase } from "../../API/cases";
import { ICase } from "../../Utils/Structures";

const validationSchema = yup.object({
  title_fa: yup.string().required(),
  title_en: yup.string().required(),
  description_fa: yup.string().required(),
  description_en: yup.string().required(),
  state: yup.string().required(),
  tags: yup.array(yup.string()).required(),
});

interface IProps {
  record: ICase;
  open: boolean;
  onClose: (refetch: boolean) => void;
}
export default function UpdateCaseDialog({ record, open, onClose }: IProps) {
  const axios = useAxios();
  const { showToast } = useContext(ToastContext);
  const [error, setError] = useState("");
  const formik = useFormik({
    initialValues: {
      title_fa: record.title.fa,
      title_en: record.title.en,
      description_fa: record.description.fa,
      description_en: record.description.en,
      state: record.state,
      tags: record.tags,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await editCase(
          record._id,
          {
            ...values,
            requests: record.requests.map((item) => item._id),
            documents: record.documents.map((item) => item._id),
          },
          axios
        );
        showToast("case edited");
        onClose(true);
      } catch (error: any) {
        console.log(error);
        setError("there is an internal error, please try again later");
      }
    },
  });
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
      fullWidth
      maxWidth="md"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Edit Case</DialogTitle>
        <DialogContent>
          <Grid container style={{ marginTop: 0 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title (farsi)"
                name="title_fa"
                size="small"
                placeholder="نام شخص به زبان فارسی"
                style={{ direction: "rtl", textAlign: "right" }}
                error={
                  formik.touched.title_fa && Boolean(formik.errors.title_fa)
                }
                helperText={formik.touched.title_fa && formik.errors.title_fa}
                value={formik.values.title_fa}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description (farsi)"
                name="description_fa"
                size="small"
                multiline
                style={{ direction: "rtl", textAlign: "right" }}
                rows={6}
                placeholder="توضیحات پرونده به زبان فارسی"
                error={
                  formik.touched.description_fa &&
                  Boolean(formik.errors.description_fa)
                }
                helperText={
                  formik.touched.description_fa && formik.errors.description_fa
                }
                value={formik.values.description_fa}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="English Title"
                name="title_en"
                size="small"
                placeholder="case full name in english language"
                error={
                  formik.touched.title_en && Boolean(formik.errors.title_en)
                }
                helperText={formik.touched.title_en && formik.errors.title_en}
                value={formik.values.title_en}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description (english)"
                name="description_en"
                size="small"
                multiline
                rows={6}
                placeholder="case description in english"
                error={
                  formik.touched.description_en &&
                  Boolean(formik.errors.description_en)
                }
                helperText={
                  formik.touched.description_en && formik.errors.description_en
                }
                value={formik.values.description_en}
                onChange={formik.handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl size="small" fullWidth>
                <InputLabel>Tags</InputLabel>
                <Select
                  value={formik.values.tags}
                  name="tags"
                  size="small"
                  onChange={formik.handleChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          size="small"
                          key={value}
                          label={getTagTitle(value, "en")}
                        />
                      ))}
                    </Box>
                  )}
                  input={<OutlinedInput label="Name" />}
                  multiple
                >
                  {tags.map((tag) => (
                    <MenuItem value={tag.slug} key={tag.slug}>
                      {tag.titles.en}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl size="small" fullWidth>
                <InputLabel>States</InputLabel>
                <Select
                  value={formik.values.state}
                  size="small"
                  name="state"
                  onChange={formik.handleChange}
                  renderValue={(selected) => getCaseStateTitle(selected, "en")}
                  input={<OutlinedInput label="Name" />}
                >
                  {states.map((state) => (
                    <MenuItem value={state.slug} key={state.slug}>
                      {state.titles.en}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!!error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => {
              onClose(false);
            }}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" style={{ minWidth: 80 }}>
            {formik.isSubmitting ? (
              <CircularProgress color="inherit" size="24.5px" />
            ) : (
              "Submit"
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
