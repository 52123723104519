import { Axios } from "axios";
import {
  ICollection,
  ILocation,
  ITask,
  ITranslatedEntity,
} from "../Utils/Structures";

export function pickTasks(count: number, axios: Axios) {
  return axios
    .post<ITask[]>("/tasks-pick", { count })
    .then((response) => response.data);
}

interface ITaskReport {
  total: number;
  byState: {
    pending: number;
    submitted: number;
    rejected: number;
    done: number;
    review: number;
    picked: number;
  };
  byType: {
    tweetLink: number;
    extractDate: number;
    extractLocation: number;
    translate: number;
    extractName: number;
  };
}
export function getTasksReport(axios: Axios) {
  return axios
    .get<ITaskReport>("/tasks-report")
    .then((response) => response.data);
}

interface ISearchTasksParams {
  type?: string;
  state?: string;
  assigneeId?: string;
  page: number;
  pageSize: number;
}
export function searchTasks(params: ISearchTasksParams, axios: Axios) {
  return axios
    .post<ICollection<ITask>>("/tasks-search", params)
    .then((response) => response.data);
}

interface IReviewTaskParams {
  id: string;
  result: "submitted" | "rejected";
}
export function reviewTask(params: IReviewTaskParams, axios: Axios) {
  return axios.post("/tasks-review", params).then(() => true);
}

/**
 * submittion requests
 */
export function verifyTweetLink(id: string, acceptState: string, axios: Axios) {
  return axios
    .post<ITask>("/tasks-tweetLink", { id, acceptState })
    .then((response) => response.data);
}

export function translate(
  id: string,
  data: { en?: string; fa?: string },
  axios: Axios
) {
  return axios
    .post<ITask>("/tasks-translate", { id, ...data })
    .then((response) => response.data);
}

export function extractName(
  id: string,
  names: ITranslatedEntity,
  axios: Axios
) {
  return axios
    .post<ITask>("/tasks-extractName", { id, names })
    .then((response) => response.data);
}

export function extractLocation(
  id: string,
  locations: ILocation,
  axios: Axios
) {
  return axios
    .post<ITask>("/tasks-extractLocation", { id, locations })
    .then((response) => response.data);
}

export function extractDate(id: string, dates: number[], axios: Axios) {
  return axios
    .post<ITask>("/tasks-extractDate", { id, dates })
    .then((response) => response.data);
}
