import { createContext, ReactElement, useState } from "react";
import SnackBar from "@mui/material/Snackbar";

export interface IToastContext {
  showToast: (message: string, time?: number) => void;
}

export const ToastContext = createContext<IToastContext>({
  showToast: () => {
    console.log("show toast");
  },
});

interface IProps {
  children: ReactElement;
}

let latestTimeout: NodeJS.Timeout;
export function ToastContextProvider({ children }: IProps) {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState<string>();

  function showToast(message: string, time = 3500) {
    setMessage(message);
    setShow(true);
    if (latestTimeout) clearTimeout(latestTimeout);
    latestTimeout = setTimeout(() => {
      setShow(false);
    }, time);
  }

  return (
    <ToastContext.Provider
      value={{
        showToast,
      }}
    >
      {children}
      <SnackBar open={show} message={message} />
    </ToastContext.Provider>
  );
}
