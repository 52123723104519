import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { searchUsers } from "../../../API/auth";
import { Loading } from "../../../Components/Loading";
import { MessageBox } from "../../../Components/MessageBox";
import Pagination from "../../../Components/Pagination";
import Paper from "../../../Components/Paper";
import SelectInput from "../../../Components/SelectInputs/SelectInput";
import TextInput from "../../../Components/TextInput/TextInput";
import { useAxios } from "../../../Providers/AuthProvider";
import UserRow from "./Components/UserRow";

export default function Manage() {
  const axios = useAxios();
  const [page, setPage] = useState(1);
  const [nickname, setNickname] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [isActive, setIsActive] = useState("all");

  const { isLoading, data, isFetching, refetch } = useQuery(
    ["manage-contributors", page],
    () => {
      return searchUsers(
        { page, pageSize: 25, nickname, username, isActive },
        axios
      );
    }
  );
  function renderLoading() {
    if (isFetching || isLoading) return <Loading>loading contributors</Loading>;
  }
  function renderNoData() {
    if (!!data && data.meta.count === 0 && !isLoading && !isFetching) {
      return <MessageBox>No contributors in found</MessageBox>;
    }
  }
  function renderTable() {
    if (!!data && !isFetching && !isLoading) {
      return (
        <Grid item xs={12}>
          <Paper>
            <Grid container>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>nickname</TableCell>
                        <TableCell>username</TableCell>
                        <TableCell>is active</TableCell>
                        <TableCell>contribution points</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.data.map((item) => (
                        <UserRow user={item} key={item._id} refetch={refetch} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {data.meta.count > 25 && (
                <Grid item xs={12}>
                  <Pagination
                    count={Math.ceil(data.meta.count / 25)}
                    page={page}
                    onChange={setPage}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      );
    }
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper>
          <Grid container>
            <Grid item xs={12} md={4}>
              <TextInput
                name="nickname"
                placeholder="nickname or part of it"
                label="nickname"
                value={nickname}
                onChange={(event) => {
                  setNickname(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput
                name="username"
                placeholder="username or part of it"
                label="username"
                value={username}
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectInput
                name="isActive"
                label="state"
                value={isActive}
                onChange={(event) => {
                  setIsActive(event.target.value);
                }}
                options={[
                  { label: "only actives", value: "true" },
                  { label: "only inactives", value: "false" },
                  { label: "all contributors", value: "all" },
                ]}
              />
            </Grid>
            <Grid item xs={12} textAlign="right">
              <Button
                variant="contained"
                disabled={isLoading && isFetching}
                onClick={() => {
                  refetch();
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {renderLoading()}
      {renderNoData()}
      {renderTable()}
    </Grid>
  );
}
