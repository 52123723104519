import { Grid } from "@mui/material";
import { useQuery } from "react-query";
import { useAxios } from "../../../Providers/AuthProvider";
import { Loading } from "../../../Components/Loading";
import { MessageBox } from "../../../Components/MessageBox";
import { pageSize } from "../../../config";
import { useState } from "react";
import Pagination from "../../../Components/Pagination";
import { searchDocuments } from "../../../API/documents";
import DocumentCard from "../../../Components/DocumentCard/DocumentCard";

interface IProps {
  caseId: string;
}
export default function ApprovedDocumentsTab({ caseId }: IProps) {
  const axios = useAxios();
  const [page, setPage] = useState(1);
  const { isLoading, isError, data, isSuccess, refetch, isRefetching } =
    useQuery(["fetchCaseDocumentsApproved", caseId], async () => {
      const result = await searchDocuments(
        { state: "approved", caseId, page, pageSize },
        axios
      );
      return result;
    });
  if (isError) {
    <MessageBox>An error occured, please try again later.</MessageBox>;
  }
  if (isRefetching || isLoading || !data) {
    return <Loading>loading requests</Loading>;
  }
  return (
    <Grid container>
      {data.meta.count === 0 && isSuccess && (
        <MessageBox>No documents found.</MessageBox>
      )}
      {data.meta.count > 0 &&
        data.data.map((item) => (
          <Grid item xs={12} key={item._id}>
            <DocumentCard record={item} />
          </Grid>
        ))}
      {data.meta.count > pageSize && (
        <Grid item xs={12}>
          <Pagination
            page={page}
            onChange={(value) => {
              setPage(value);
              setTimeout(() => {
                refetch();
              }, 0);
            }}
            count={Math.ceil(data.meta.count / pageSize)}
          />
        </Grid>
      )}
    </Grid>
  );
}
