import { Grid } from "@mui/material";
import Typography from "../../Typography";
import { IDocument } from "../../../Utils/Structures";
import ContributorBadge from "../../ContributorBadge";

interface IProps {
  record: IDocument;
}
export default function Contributors({ record }: IProps) {
  return (
    <>
      <Grid item xs={12}>
        <Typography lang="en" variant="body2">
          Contributors:
        </Typography>
      </Grid>
      {record.contributors.map((item, index) => (
        <Grid item key={index} xs={12}>
          <ContributorBadge user={item} />
        </Grid>
      ))}
    </>
  );
}
