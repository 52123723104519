import { Grid } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { searchRequests } from "../../API/requests";
import { Loading } from "../../Components/Loading";
import { MessageBox } from "../../Components/MessageBox";
import Pagination from "../../Components/Pagination";
import RequestCard from "../../Components/RequestCard/RequestCard";
import { pageSize } from "../../config";
import { useAxios } from "../../Providers/AuthProvider";
import SearchForm from "./Components/SearchForm/SearchForm";
export default function Requests() {
  const axios = useAxios();
  const [page, setPage] = useState(1);
  const [fullName, setFullName] = useState("");
  const { isLoading, isError, isSuccess, data, isFetching, refetch } = useQuery(
    "searchRequests",
    async () => {
      const results = await searchRequests({ page, pageSize, fullName }, axios);
      return results;
    }
  );

  function renderData() {
    if (!data) return;
    if (isFetching) {
      return <Loading>loading requests</Loading>;
    }
    const count = data.meta.count;
    const records = data.data;
    return (
      <>
        {count === 0 && isSuccess && (
          <MessageBox>No requests found.</MessageBox>
        )}
        {count > 0 &&
          records.map((item) => {
            return (
              <Grid item xs={12} key={item._id}>
                <RequestCard record={item} navigateToCase />
              </Grid>
            );
          })}
        {count > pageSize && (
          <Grid item xs={12}>
            <Pagination
              page={page}
              onChange={(value) => {
                setPage(value);
                setTimeout(() => {
                  refetch();
                }, 0);
              }}
              count={Math.ceil(count / pageSize)}
            />
          </Grid>
        )}
      </>
    );
  }
  if (isLoading) {
    return <Loading>loading requests</Loading>;
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <SearchForm
          fullName={fullName}
          setFullName={setFullName}
          onSearch={() => {}}
        />
      </Grid>
      {isError && (
        <MessageBox>An error occured, please try again later.</MessageBox>
      )}
      {renderData()}
    </Grid>
  );
}
