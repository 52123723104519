import {
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { MessageBox } from "../../Components/MessageBox";
import Paper from "../../Components/Paper";
import { AuthContext } from "../../Providers/AuthProvider";

import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";

import ApprovedTasksTab from "./Components/ApprovedTasksTab";
import CommitedTasksTab from "./Components/CommitedTasksTab";
import DocumentsTab from "./Components/DocumentsTab";
import PickedTasksTab from "./Components/PickedTasksTab";
import RejectedTasksTab from "./Components/RejectedTasksTab";
import { ToastContext } from "../../Providers/ToastProvider";
import ProfileDialog from "../../Components/Auth/ProfileDialog";

export default function Profile() {
  const { profile } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const [openProfile, setOpenProfile] = useState(false);
  const [activeTab, setActiveTab] = useState("approved");
  if (!profile) {
    return (
      <MessageBox>
        you are not logged in. please log in to have access to this content
      </MessageBox>
    );
  }
  return (
    <Grid container>
      <ProfileDialog
        profile={profile}
        open={openProfile}
        onClose={() => {
          setOpenProfile(false);
        }}
      />
      <Grid item xs={12}>
        <Paper>
          <Grid container>
            <Grid item xs={12} textAlign="right">
              <Tooltip title="edit your profile">
                <IconButton
                  onClick={() => {
                    setOpenProfile(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="share your profile">
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(
                      window.location.host + "/contributor/" + profile._id
                    );
                    showToast("your profile link coppied to your clipboard");
                  }}
                >
                  <ShareIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2">
                username: {profile.username}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2">
                nickname: {profile.nickname}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2">
                contributation points: {profile.contributionPoints}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2">role: {profile.role}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container style={{ marginTop: 0 }}>
            <Grid item xs={12}>
              <Tabs value={activeTab}>
                <Tab
                  label="documents"
                  value="documents"
                  onClick={() => {
                    setActiveTab("documents");
                  }}
                />
                <Tab
                  label="commited tasks"
                  value="committed"
                  onClick={() => {
                    setActiveTab("committed");
                  }}
                />
                <Tab
                  label="picked tasks"
                  value="picked"
                  onClick={() => {
                    setActiveTab("picked");
                  }}
                />
                <Tab
                  label="approved tasks"
                  value="approved"
                  onClick={() => {
                    setActiveTab("approved");
                  }}
                />
                <Tab
                  label="rejected tasks"
                  value="rejected"
                  onClick={() => {
                    setActiveTab("rejected");
                  }}
                />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              {activeTab === "documents" && <DocumentsTab id={profile._id} />}
              {activeTab === "committed" && (
                <CommitedTasksTab id={profile._id} />
              )}
              {activeTab === "picked" && <PickedTasksTab id={profile._id} />}
              {activeTab === "approved" && (
                <ApprovedTasksTab id={profile._id} />
              )}
              {activeTab === "rejected" && (
                <RejectedTasksTab id={profile._id} />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
