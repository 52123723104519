import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import { useAxios } from "../../Providers/AuthProvider";
import { ToastContext } from "../../Providers/ToastProvider";
import { IRequest } from "../../Utils/Structures";
import { editRequest } from "../../API/requests";
import { useQueryClient } from "react-query";

const validationSchema = yup.object({
  title_fa: yup.string().required(),
  title_en: yup.string().required(),
  description_fa: yup.string().required(),
  description_en: yup.string().required(),
});

interface IProps {
  record: IRequest;
  open: boolean;
  onClose: (refetch: boolean) => void;
}
export default function UpdateRequestDialog({ record, open, onClose }: IProps) {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);
  const [error, setError] = useState("");
  const formik = useFormik({
    initialValues: {
      title_fa: record.title.fa,
      title_en: record.title.en,
      description_fa: record.description.fa,
      description_en: record.description.en,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await editRequest(
          record._id,
          {
            ...values,
            caseId: record.case._id,
          },
          axios
        );
        queryClient.refetchQueries("searchRequests");
        queryClient.refetchQueries("fetchCaseRequests");
        showToast("request edited");
        onClose(true);
      } catch (error: any) {
        console.log(error);
        setError("there is an internal error, please try again later");
      }
    },
  });
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
      fullWidth
      maxWidth="md"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Edit Request</DialogTitle>
        <DialogContent>
          <Grid container style={{ marginTop: 0 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title (farsi)"
                name="title_fa"
                size="small"
                placeholder="نام شخص به زبان فارسی"
                style={{ direction: "rtl", textAlign: "right" }}
                error={
                  formik.touched.title_fa && Boolean(formik.errors.title_fa)
                }
                helperText={formik.touched.title_fa && formik.errors.title_fa}
                value={formik.values.title_fa}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description (farsi)"
                name="description_fa"
                size="small"
                multiline
                style={{ direction: "rtl", textAlign: "right" }}
                rows={6}
                placeholder="توضیحات پرونده به زبان فارسی"
                error={
                  formik.touched.description_fa &&
                  Boolean(formik.errors.description_fa)
                }
                helperText={
                  formik.touched.description_fa && formik.errors.description_fa
                }
                value={formik.values.description_fa}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="English Title"
                name="title_en"
                size="small"
                placeholder="case full name in english language"
                error={
                  formik.touched.title_en && Boolean(formik.errors.title_en)
                }
                helperText={formik.touched.title_en && formik.errors.title_en}
                value={formik.values.title_en}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description (english)"
                name="description_en"
                size="small"
                multiline
                rows={6}
                placeholder="case description in english"
                error={
                  formik.touched.description_en &&
                  Boolean(formik.errors.description_en)
                }
                helperText={
                  formik.touched.description_en && formik.errors.description_en
                }
                value={formik.values.description_en}
                onChange={formik.handleChange}
              />
            </Grid>
            {!!error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => {
              onClose(false);
            }}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" style={{ minWidth: 80 }}>
            {formik.isSubmitting ? (
              <CircularProgress color="inherit" size="24.5px" />
            ) : (
              "Submit"
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
