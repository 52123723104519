import { Grid, CircularProgress, Typography } from "@mui/material";

interface IProps {
  children?: JSX.Element | string;
}
export function Loading({ children }: IProps) {
  return (
    <Grid container>
      <Grid item xs={12} style={{ marginTop: 32, textAlign: "center" }}>
        <CircularProgress />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 24, textAlign: "center" }}>
        <Typography variant="body1">{children}</Typography>
      </Grid>
    </Grid>
  );
}
