import { Grid } from "@mui/material";
import { useState } from "react";
import { DocumentState, DocumentType } from "../../Utils/Structures";
import Pagination from "../../Components/Pagination";
import SearchForm from "./Components/SearchForm/SearchForm";
import { useQuery } from "react-query";
import { searchDocuments } from "../../API/documents";
import { pageSize } from "../../config";
import { useAxios } from "../../Providers/AuthProvider";
import { Loading } from "../../Components/Loading";
import { MessageBox } from "../../Components/MessageBox";
import DocumentCard from "../../Components/DocumentCard/DocumentCard";

export default function Documents() {
  const axios = useAxios();
  const [page, setPage] = useState(1);
  const [term, setTerm] = useState("");
  const [types, setTypes] = useState<DocumentType[]>([]);
  const [states, setStates] = useState<DocumentState[]>([]);

  const { isLoading, isError, isSuccess, data, isFetching, refetch } = useQuery(
    "searchDocuments",
    async () => {
      const results = await searchDocuments(
        { page, pageSize, term, types, states },
        axios
      );
      return results;
    }
  );

  function renderData() {
    if (!data) return;
    if (isFetching) {
      return <Loading>loading documents</Loading>;
    }
    const count = data.meta.count;
    const records = data.data;
    return (
      <>
        {count === 0 && isSuccess && (
          <MessageBox>No documents found.</MessageBox>
        )}
        {count > 0 &&
          records.map((item) => {
            return (
              <Grid item xs={12} key={item._id}>
                <DocumentCard record={item} showState navigateToCase />
              </Grid>
            );
          })}
        {count > pageSize && (
          <Grid item xs={12}>
            <Pagination
              page={page}
              onChange={(value) => {
                setPage(value);
                setTimeout(() => {
                  refetch();
                }, 0);
              }}
              count={Math.ceil(count / pageSize)}
            />
          </Grid>
        )}
      </>
    );
  }
  if (isLoading) {
    return <Loading>loading documents</Loading>;
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <SearchForm
          searching={isLoading || isFetching}
          onSearch={(params) => {
            setTerm(params.term);
            setTypes(params.types);
            setStates(params.states);
            setTimeout(() => {
              refetch();
            });
          }}
        />
      </Grid>
      {isError && (
        <MessageBox>An error occured, please try again later.</MessageBox>
      )}
      {renderData()}
    </Grid>
  );
}
