import {
  createContext,
  ReactElement,
  useState,
  useContext,
  useEffect,
} from "react";
import LoginDialog from "../Components/Auth/LoginDialog";
import LogoutDialog from "../Components/Auth/LogoutDialog";
import { now } from "../functions";
import { IUser } from "../Utils/Structures";
import { ToastContext } from "./ToastProvider";
import Axios from "axios";
import { IAuthResponse } from "../API/auth";
import { isAuthError } from "../API/axios";
import ErrorIcon from "@mui/icons-material/Error";
import { Grid, CircularProgress, Typography } from "@mui/material";
import { useQuery } from "react-query";
import RegisterDialog from "../Components/Auth/RegisterDialog";

export interface IAuthContext {
  openLoginDialog: () => void;
  openLogoutDialog: () => void;
  openRegisterDialog: () => void;
  updateProfile: (p: IUser) => void;
  profile?: IUser;
  token?: string;
  expiresAt?: number;
  authorize: (p: IUser, t: string, e: number) => void;
  unauthorize: () => void;
}

export const AuthContext = createContext<IAuthContext>({
  openLoginDialog: () => {
    console.log("open login dialog");
  },
  openRegisterDialog: () => {
    console.log("open register dialog");
  },
  openLogoutDialog: () => {
    console.log("open logout dialog");
  },
  authorize: () => {
    console.log("open login dialog");
  },
  unauthorize: () => {
    console.log("open login dialog");
  },
  updateProfile: () => {
    console.log("update local profile");
  },
});
interface IProps {
  children: ReactElement;
}
export function AuthContextProvider({ children }: IProps) {
  const { showToast } = useContext(ToastContext);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [registerDialogOpen, setRegisterDialogOpen] = useState(false);

  const [profile, setProfile] = useState<IUser>();
  const [token, setToken] = useState<string>();
  const [expiresAt, setExpiresAt] = useState<number>();

  const [loading, setLoading] = useState(true);
  const [connectionError, setConnectionError] = useState(false);

  function updateProfile(p: IUser) {
    setProfile(p);
    localStorage.setItem("profile", JSON.stringify(p));
  }
  function authorize(p: IUser, t: string, e: number) {
    setProfile(p);
    setToken(t);
    setExpiresAt(e);
    localStorage.setItem("profile", JSON.stringify(p));
    localStorage.setItem("token", t);
    localStorage.setItem("expiresAt", e + "");
  }

  function unauthorize() {
    setProfile(undefined);
    setToken(undefined);
    setExpiresAt(undefined);
    localStorage.removeItem("profile");
    localStorage.removeItem("token");
    localStorage.removeItem("expiresAt");
  }

  useQuery(
    ["fetch-profile", token],
    async () => {
      if (!token) return;
      console.log("fetching profile", token);
      try {
        const result = await Axios.post<IAuthResponse>(
          "/auth-getProfile",
          {},
          {
            baseURL: process.env["REACT_APP_BASE_URL"],
            headers: { "x-token": token },
          }
        );
        const data = result.data;
        authorize(
          {
            _id: data._id,
            nickname: data.nickname,
            username: data.username,
            isActive: data.isActive,
            contributionPoints: data.contributionPoints,
            role: data.role,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
          },
          data.accessToken,
          data.expiresAt
        );
        if (loading) {
          showToast("welcome " + data.nickname);
        }
      } catch (error) {
        if (isAuthError(error)) {
          showToast("session expires, logged out");
          unauthorize();
        } else {
          setConnectionError(true);
        }
      } finally {
        setLoading(false);
      }
      return {};
    },
    { refetchInterval: 60000 * 60 }
  );

  useEffect(() => {
    const p = localStorage.getItem("profile");
    const t = localStorage.getItem("token");
    const e = localStorage.getItem("expiresAt");
    if (p && t && e && parseInt(e) > now()) {
      try {
        const pObj = JSON.parse(p);
        authorize(pObj, t, parseInt(e));
      } catch {
        unauthorize();
        setLoading(false);
      }
    } else {
      unauthorize();
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <Grid container>
        <Grid item xs={12} style={{ marginTop: 32, textAlign: "center" }}>
          <CircularProgress />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 24, textAlign: "center" }}>
          <Typography variant="body1">Loading content...</Typography>
        </Grid>
      </Grid>
    );
  }
  if (connectionError) {
    return (
      <Grid container>
        <Grid item xs={12} style={{ marginTop: 32, textAlign: "center" }}>
          <ErrorIcon fontSize="large" color="error" />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 4, textAlign: "center" }}>
          <Typography variant="body1">
            There is an error, please check your internet connection or try
            later.
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <AuthContext.Provider
      value={{
        openRegisterDialog: () => {
          setRegisterDialogOpen(true);
        },
        openLoginDialog: () => {
          setLoginDialogOpen(true);
        },
        openLogoutDialog: () => {
          setLogoutDialogOpen(true);
        },
        updateProfile,
        profile,
        token,
        expiresAt,
        authorize,
        unauthorize,
      }}
    >
      {children}
      <RegisterDialog
        open={registerDialogOpen}
        onClose={() => {
          setRegisterDialogOpen(false);
        }}
      />
      <LoginDialog
        open={loginDialogOpen}
        onClose={() => {
          setLoginDialogOpen(false);
        }}
      />
      <LogoutDialog
        open={logoutDialogOpen}
        onClose={() => {
          setLogoutDialogOpen(false);
        }}
      />
    </AuthContext.Provider>
  );
}

export function useOpenRegister() {
  const { openRegisterDialog } = useContext(AuthContext);
  return openRegisterDialog;
}
export function useOpenLogin() {
  const { openLoginDialog } = useContext(AuthContext);
  return openLoginDialog;
}
export function useOpenLogout() {
  const { openLogoutDialog } = useContext(AuthContext);
  return openLogoutDialog;
}

export function useIsLoggedIn() {
  const { token, expiresAt } = useContext(AuthContext);
  return !!token && !!expiresAt && expiresAt > now();
}

export function useIsRegulator() {
  const isLoggedIn = useIsLoggedIn();
  const { profile } = useContext(AuthContext);
  return isLoggedIn && !!profile && profile.role === "regulator";
}

export function useIsContributor() {
  const isLoggedIn = useIsLoggedIn();
  const { profile } = useContext(AuthContext);
  return isLoggedIn && !!profile && profile.role === "contributor";
}

export function useAxios() {
  const { token } = useContext(AuthContext);
  const headers: { [key: string]: string } = {};
  if (token) {
    headers["x-token"] = token;
  }
  return Axios.create({ baseURL: process.env["REACT_APP_BASE_URL"], headers });
}
