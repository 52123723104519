import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { ITask } from "../../../../Utils/Structures";
import { tsToTimeDate } from "../../../../Utils/time";
import DescriptionIcon from "@mui/icons-material/Description";
import { useState } from "react";
import TaskDetails from "./TaskDetails";
import { Link } from "react-router-dom";

interface IProps {
  task: ITask;
}
export default function TaskRow({ task }: IProps) {
  const [detailsOpen, setDetailsOpen] = useState(false);
  function taskType() {
    switch (task.type) {
      case "tweetLink":
        return "tweet verification";
      default:
        return task.type;
    }
  }
  function taskState() {
    switch (task.state) {
      case "pending":
        return "pending";
      case "picked":
        return "picked by contributor";
      case "review":
        return "under regulator review";
      case "submited":
        return "waiting to be commited";
      case "done":
        return "commited in repo";
      case "rejected":
        return "rejected by regulator";
      default:
        return task.state;
    }
  }
  function taskAssignee() {
    if (task.assignee) {
      return (
        <Link to={"/contributor/" + task.assignee._id}>
          {task.assignee.name}
        </Link>
      );
    } else {
      return "N.A";
    }
  }
  return (
    <TableRow style={{ height: 74 }}>
      <TableCell>{taskType()}</TableCell>
      <TableCell>{taskState()}</TableCell>
      <TableCell>{taskAssignee()}</TableCell>
      <TableCell>{tsToTimeDate(task.createdAt)}</TableCell>
      <TableCell>{tsToTimeDate(task.updatedAt)}</TableCell>
      <TableCell>
        <Tooltip title="details">
          <IconButton
            onClick={() => {
              setDetailsOpen(true);
            }}
          >
            <DescriptionIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
      <TaskDetails
        task={task}
        open={detailsOpen}
        onClose={() => {
          setDetailsOpen(false);
        }}
      />
    </TableRow>
  );
}
