import { IconButton, Menu, MenuItem } from "@mui/material";
import { ReactElement, useState } from "react";

interface IProps {
  children: ReactElement;
  items: { onClick: () => void; title: string }[];
}
export default function DropDownMenu({ children, items }: IProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton color="inherit" onClick={handleClick}>
        {children}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {items.map((item) => (
          <MenuItem
            key={item.title}
            onClick={() => {
              item.onClick();
              setAnchorEl(null);
            }}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
