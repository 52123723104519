import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import InfoIcon from "@mui/icons-material/Info";
import { getLeaderboard } from "../../../API/auth";
import { Loading } from "../../../Components/Loading";
import { MessageBox } from "../../../Components/MessageBox";
import Pagination from "../../../Components/Pagination";
import { useAxios } from "../../../Providers/AuthProvider";
import { useNavigate } from "react-router-dom";

export default function Leaderboard() {
  const axios = useAxios();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { isLoading, data, isFetching } = useQuery(
    ["leaderboard", page],
    () => {
      return getLeaderboard(page, axios);
    }
  );
  if (isFetching || isLoading)
    return (
      <Grid container>
        <Loading>loading contributors</Loading>
      </Grid>
    );
  if (!data || data.meta.count === 0) {
    return (
      <Grid container>
        <MessageBox>No contributors in project</MessageBox>
      </Grid>
    );
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>nickname</TableCell>
                <TableCell>contribution points</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.data.map((item) => {
                return (
                  <TableRow key={item._id}>
                    <TableCell>{item.nickname}</TableCell>
                    <TableCell>{item.contributionPoints}</TableCell>
                    <TableCell>
                      <Tooltip title="view details">
                        <IconButton
                          onClick={() => {
                            navigate("/contributor/" + item._id);
                          }}
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {data.meta.count > 25 && (
        <Grid item xs={12}>
          <Pagination
            count={Math.ceil(data.meta.count / 25)}
            page={page}
            onChange={setPage}
          />
        </Grid>
      )}
    </Grid>
  );
}
