import Chip from "@mui/material/Chip";
interface IProps {
  children: string;
}
export default function Tag({ children }: IProps) {
  return (
    <Chip
      size="small"
      style={{ marginRight: 8 }}
      color="primary"
      label={children}
      variant="outlined"
    />
  );
}
