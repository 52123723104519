import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { searchTasks } from "../../../API/tasks";
import { Loading } from "../../../Components/Loading";
import { MessageBox } from "../../../Components/MessageBox";
import Pagination from "../../../Components/Pagination";
import Paper from "../../../Components/Paper";
import SelectInput from "../../../Components/SelectInputs/SelectInput";
import { useAxios } from "../../../Providers/AuthProvider";
import TaskRow from "./Components/TaskRow";

export default function Search() {
  const axios = useAxios();
  const [page, setPage] = useState(1);
  const [state, setState] = useState("all");
  const [type, setType] = useState("all");
  const { isLoading, isFetching, data, refetch } = useQuery(
    ["search-tasks", page],
    () => {
      return searchTasks({ page, pageSize: 10, type, state }, axios);
    }
  );
  function loadingView() {
    if (isLoading || isFetching || !data) {
      return <Loading>Loading tasks</Loading>;
    }
  }
  function noRecords() {
    if (isLoading || isFetching) return;
    if (data && data.meta.count === 0) {
      return <MessageBox>no task found</MessageBox>;
    }
  }
  function dataView() {
    if (!data || isLoading || isFetching) return;
    if (data.data.length === 0) return;
    const tasks = data.data;
    const count = data.meta.count;
    return (
      <Grid item xs={12}>
        <Paper>
          <Grid container>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Assignee</TableCell>
                    <TableCell>Created at</TableCell>
                    <TableCell>Last update at</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tasks.map((task) => (
                    <TaskRow key={task._id} task={task} />
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12}>
              <Pagination
                count={Math.ceil(count / 25)}
                page={page}
                onChange={setPage}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper>
          <Grid container>
            <Grid item xs={3}>
              <SelectInput
                name="state"
                label="Task state"
                value={state}
                onChange={(event) => {
                  setState(event.target.value);
                }}
                options={[
                  { label: "all states", value: "all" },
                  { label: "pending", value: "pending" },
                  { label: "submitted", value: "submitted" },
                  { label: "commited in repo", value: "done" },
                  { label: "pending review", value: "review" },
                  { label: "rejected", value: "rejected" },
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectInput
                name="type"
                label="Task type"
                value={type}
                onChange={(event) => {
                  setType(event.target.value);
                }}
                options={[
                  { label: "all types", value: "all" },
                  { label: "verify link", value: "tweetLink" },
                  { label: "extract names", value: "extractName" },
                  { label: "extract dates", value: "extractDate" },
                  { label: "extract locations", value: "extractLocation" },
                  { label: "translate", value: "translate" },
                ]}
              />
            </Grid>
            <Grid item xs={12} textAlign="right">
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  refetch();
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {loadingView()}
      {noRecords()}
      {dataView()}
    </Grid>
  );
}
