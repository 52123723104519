import tags from "./tags.json";
import CStates from "./case-states.json";
import DStates from "./document-states.json";
import DTypes from "./document-types.json";
export function getTagTitle(slug: string, lang: "fa" | "en" = "fa") {
  const tag = tags.find((item) => item.slug === slug);
  if (!tag) {
    return "N.A";
  } else {
    return tag.titles[lang];
  }
}

export function getCaseStateTitle(slug: string, lang: "fa" | "en" = "fa") {
  const state = CStates.find((item) => item.slug === slug);
  if (!state) {
    return "N.A";
  } else {
    return state.titles[lang];
  }
}

export function getDocumentStateTitle(slug: string, lang: "fa" | "en" = "fa") {
  const state = DStates.find((item) => item.slug === slug);
  if (!state) {
    return "N.A";
  } else {
    return state.titles[lang];
  }
}

export function getDocumentTypeTitle(slug: string, lang: "fa" | "en" = "fa") {
  const state = DTypes.find((item) => item.slug === slug);
  if (!state) {
    return "N.A";
  } else {
    return state.titles[lang];
  }
}
