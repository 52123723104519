import { Grid, Chip } from "@mui/material";
import { IDocument } from "../../../Utils/Structures";
import Typography from "../../Typography";

interface IProps {
  record: IDocument;
}
export default function Names({ record }: IProps) {
  return (
    <>
      <Grid item xs={12}>
        <Typography lang="en" variant="body2">
          Names:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {record.names.map((item, index) => (
          <Chip
            key={index}
            label={`${item.fa} - ${item.en}`}
            variant="outlined"
          />
        ))}
      </Grid>
    </>
  );
}
