import { createTheme, ThemeProvider as ThProvider } from "@mui/material";

const theme = createTheme({
  components: {
    MuiGrid: {
      defaultProps: {
        spacing: "12px",
      },
    },
  },
});

interface IProps {
  children: React.ReactElement;
}
export default function ThemeProvider({ children }: IProps) {
  return <ThProvider theme={theme}>{children}</ThProvider>;
}
