import moment from "moment";

export function now() {
  return Math.ceil(Date.now() / 1000);
}
export function tsToStr(
  ts: number,
  format: "date" | "datetime" | "from" = "date"
) {
  const mnt = moment(ts * 1000);
  switch (format) {
    case "date":
      return mnt.format("YYYY/MM/DD");
    case "datetime":
      return mnt.format("YYYY/MM/DD HH:mm");
    case "from":
      return mnt.fromNow();
  }
}
export function generateRandomString(length = 32) {
  const chars = "1234567890qwertyuiopasdfghjklzxcvbnm";
  const count = chars.length;
  let result = "";
  for (let i = 0; i < length; i++) {
    result += chars.at(Math.floor(Math.random() * count));
  }
  return result;
}
