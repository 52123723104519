import { Grid } from "@mui/material";
import { useQuery } from "react-query";
import { searchTasks } from "../../../API/tasks";
import { Loading } from "../../../Components/Loading";
import { MessageBox } from "../../../Components/MessageBox";
import Paper from "../../../Components/Paper";
import { useAxios } from "../../../Providers/AuthProvider";
import { ITask } from "../../../Utils/Structures";
import ApprovalCard from "./Compnents/ApprovalCard";
import TweetLink from "./Compnents/TweetLink";

export default function Review() {
  const axios = useAxios();
  const { isLoading, isFetching, data } = useQuery(["review-task"], () => {
    return searchTasks({ page: 1, pageSize: 1, state: "review" }, axios);
  });
  function loadingView() {
    if (isLoading || isFetching || !data) {
      return <Loading>Loading tasks</Loading>;
    }
  }
  function noRecords() {
    if (isLoading || isFetching) return;
    if (data && data.meta.count === 0) {
      return <MessageBox>no task found to review</MessageBox>;
    }
  }
  function renderData(task: ITask) {
    switch (task.type) {
      case "tweetLink":
        return <TweetLink task={task} />;
      default:
        return task.type;
    }
  }
  function renderTask() {
    if (isLoading || isFetching || !data) return;
    if (data.data.length === 0) return;
    const task = data.data[0];
    return (
      <>
        <Grid item xs={12}>
          <Paper>{renderData(task)}</Paper>
        </Grid>
        <ApprovalCard task={task} />
      </>
    );
  }
  return (
    <Grid container>
      {loadingView()}
      {noRecords()}
      {renderTask()}
    </Grid>
  );
}
