import { Chip, Grid } from "@mui/material";
import { IDocument } from "../../../Utils/Structures";
import Typography from "../../Typography";

interface IProps {
  record: IDocument;
}
export default function Location({ record }: IProps) {
  return (
    <>
      <Grid item xs={12}>
        <Typography lang="en" variant="body2">
          Locations:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {record.locations.map((item, index) => {
          const label = `${item.city} - ${item.province} - ${item.country}`;
          return <Chip key={index} label={label} variant="outlined" />;
        })}
      </Grid>
    </>
  );
}
