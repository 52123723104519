import { Grid, Typography } from "@mui/material";
import { Tweet } from "react-twitter-widgets";
import { ITask } from "../../../../Utils/Structures";

interface IProps {
  task: ITask;
}
export default function TweetLinkDetails({ task }: IProps) {
  if (!task.source) return null;

  const tweetId = (task.source || "").split("/")[5];

  function assignment() {
    if (!task.assignee) return;
    if (!task.result.acceptState) {
      return (
        <Grid item xs={12}>
          <Typography variant="caption">
            this task is assigned to {task.assignee.name}
          </Typography>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12}>
          <Typography variant="caption">
            {task.assignee.name} descided this link is {task.result.acceptState}
          </Typography>
        </Grid>
      );
    }
  }
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Tweet tweetId={tweetId} options={{ conversation: "none" }} />
      </Grid>
      {assignment()}
    </Grid>
  );
}
