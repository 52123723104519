import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Case from "./Containers/Case/Case";
// import Home from "./Containers/Home/Home";
import Document from "./Containers/Document/Document";
import PublicLayout from "./PublicLayout/PublicLayout";
import Documents from "./Containers/Documents/Documents";
import Requests from "./Containers/Reuqests/Reuqests";
import ThemeProvider from "./ThemeProvider";
import { AuthContextProvider } from "./Providers/AuthProvider";
import { ToastContextProvider } from "./Providers/ToastProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import PickTasks from "./Containers/Tasks/PickTasks/PickTasks";
import { FirebaseContextProvider } from "./Providers/FirebaseProvider";
import TasksReport from "./Containers/Tasks/TasksReport/TasksReport";
import Leaderboard from "./Containers/Contributors/Leaderboard/Leaderboard";
import ManageContributors from "./Containers/Contributors/Manage/Manage";
import SearchTasks from "./Containers/Tasks/Search/Search";
import ReviewTask from "./Containers/Tasks/Review/Review";
import Profile from "./Containers/Profile/Profile";
import Contributor from "./Containers/Profile/Contributor";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PublicLayout>
        <Documents />
      </PublicLayout>
    ),
  },
  {
    path: "/case/:id",
    element: (
      <PublicLayout>
        <Case />
      </PublicLayout>
    ),
  },
  {
    path: "/document/:id",
    element: (
      <PublicLayout>
        <Document />
      </PublicLayout>
    ),
  },
  {
    path: "/documents",
    element: (
      <PublicLayout>
        <Documents />
      </PublicLayout>
    ),
  },
  {
    path: "/contributors/leaderboard",
    element: (
      <PublicLayout>
        <Leaderboard />
      </PublicLayout>
    ),
  },
  {
    path: "/contributors/manage",
    element: (
      <PublicLayout>
        <ManageContributors />
      </PublicLayout>
    ),
  },
  {
    path: "/requests",
    element: (
      <PublicLayout>
        <Requests />
      </PublicLayout>
    ),
  },
  {
    path: "/tasks/pick",
    element: (
      <PublicLayout>
        <PickTasks />
      </PublicLayout>
    ),
  },
  {
    path: "/tasks/report",
    element: (
      <PublicLayout>
        <TasksReport />
      </PublicLayout>
    ),
  },
  {
    path: "/tasks/search",
    element: (
      <PublicLayout>
        <SearchTasks />
      </PublicLayout>
    ),
  },
  {
    path: "/tasks/review",
    element: (
      <PublicLayout>
        <ReviewTask />
      </PublicLayout>
    ),
  },
  {
    path: "/profile",
    element: (
      <PublicLayout>
        <Profile />
      </PublicLayout>
    ),
  },
  {
    path: "/contributor/:id",
    element: (
      <PublicLayout>
        <Contributor />
      </PublicLayout>
    ),
  },
]);
function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { refetchOnMount: false, refetchOnWindowFocus: false },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <FirebaseContextProvider>
        <ToastContextProvider>
          <ThemeProvider>
            <AuthContextProvider>
              <RouterProvider router={router} />
            </AuthContextProvider>
          </ThemeProvider>
        </ToastContextProvider>
      </FirebaseContextProvider>
    </QueryClientProvider>
  );
}

export default App;
