import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { useContext } from "react";
import * as yup from "yup";
import { AuthContext, useAxios } from "../../Providers/AuthProvider";
import TextInput from "../TextInput/TextInput";
import { IUser } from "../../Utils/Structures";
import { useFormik } from "formik";
import { updateProfile } from "../../API/auth";
import { ToastContext } from "../../Providers/ToastProvider";
import { Alert, Button, CircularProgress } from "@mui/material";
import { tsToStr } from "../../functions";

const validationSchema = yup.object({
  nickname: yup.string().required("your nickname is required"),
  contact: yup.string().optional(),
  username: yup.string().required("your username is required"),
  password: yup.string().optional(),
});

interface IProps {
  profile: IUser;
  open: boolean;
  onClose: () => void;
}
export default function ProfileDialog({ profile, open, onClose }: IProps) {
  const { showToast } = useContext(ToastContext);
  const { updateProfile: updateLocalProfile } = useContext(AuthContext);
  const axios = useAxios();
  const formik = useFormik({
    initialValues: {
      username: profile.username,
      nickname: profile.nickname,
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const user = await updateProfile(values, axios);
        updateLocalProfile(user);
        showToast("profile updated");
        onClose();
      } catch (error: any) {
        console.log(error);
        showToast("there is an internal error, please try again later");
      }
    },
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Your profile information</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container style={{ marginTop: 0 }}>
            <Grid item xs={12}>
              <Typography variant="body2">
                your contribution points: {profile.contributionPoints} cp(s)
              </Typography>
            </Grid>
            {profile.contributionPoints < 100 && (
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <Alert severity="info">
                  since your contribution points are less than 100, any
                  contribution you make on project must get an approval from a
                  regulator.
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextInput
                label="Nickname"
                placeholder="your nickname"
                value={formik.values.nickname}
                onChange={formik.handleChange}
                name="nickname"
                error={
                  formik.touched.nickname ? formik.errors.nickname : undefined
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Username"
                placeholder="your username"
                value={formik.values.username}
                onChange={formik.handleChange}
                name="username"
                error={
                  formik.touched.username ? formik.errors.username : undefined
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Password"
                type="password"
                placeholder="your password"
                value={formik.values.password}
                onChange={formik.handleChange}
                name="password"
                error={
                  formik.touched.password ? formik.errors.password : undefined
                }
              />
            </Grid>
            <Grid item xs={12} textAlign="right">
              <Button
                type="submit"
                variant="contained"
                style={{ minWidth: 80 }}
              >
                {formik.isSubmitting ? (
                  <CircularProgress color="inherit" size="24.5px" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Registered at: {tsToStr(profile.createdAt, "datetime")}
              </Typography>
              <Typography variant="body1">
                Last updated at: {tsToStr(profile.updatedAt, "from")}
              </Typography>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
