import { Grid, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { searchUsers } from "../../API/auth";
import { Loading } from "../../Components/Loading";
import { MessageBox } from "../../Components/MessageBox";
import Paper from "../../Components/Paper";
import { useAxios } from "../../Providers/AuthProvider";
import ApprovedTasksTab from "./Components/ApprovedTasksTab";
import CommitedTasksTab from "./Components/CommitedTasksTab";
import DocumentsTab from "./Components/DocumentsTab";
import PickedTasksTab from "./Components/PickedTasksTab";
import RejectedTasksTab from "./Components/RejectedTasksTab";

export default function Contributor() {
  const { id } = useParams<{ id: string }>();
  const axios = useAxios();
  const [activeTab, setActiveTab] = useState("approved");
  const { isLoading, isFetching, data } = useQuery(
    ["fetch-contributor", id],
    async () => {
      const result = await searchUsers({ id, page: 1, pageSize: 1 }, axios);
      return result;
    }
  );
  if (isLoading || isFetching || !data) {
    return <Loading>loading data</Loading>;
  }
  if (!data || data.data.length === 0) {
    return <MessageBox>contributor not found</MessageBox>;
  }
  const user = data.data[0];
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="body2">nickname: {user.nickname}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2">
                contributation points: {user.contributionPoints}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2">role: {user.role}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container style={{ marginTop: 0 }}>
            <Grid item xs={12}>
              <Tabs value={activeTab}>
                <Tab
                  label="documents"
                  value="documents"
                  onClick={() => {
                    setActiveTab("documents");
                  }}
                />
                <Tab
                  label="commited tasks"
                  value="committed"
                  onClick={() => {
                    setActiveTab("committed");
                  }}
                />
                <Tab
                  label="picked tasks"
                  value="picked"
                  onClick={() => {
                    setActiveTab("picked");
                  }}
                />
                <Tab
                  label="approved tasks"
                  value="approved"
                  onClick={() => {
                    setActiveTab("approved");
                  }}
                />
                <Tab
                  label="rejected tasks"
                  value="rejected"
                  onClick={() => {
                    setActiveTab("rejected");
                  }}
                />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              {activeTab === "documents" && <DocumentsTab id={user._id} />}
              {activeTab === "committed" && <CommitedTasksTab id={user._id} />}
              {activeTab === "picked" && <PickedTasksTab id={user._id} />}
              {activeTab === "approved" && <ApprovedTasksTab id={user._id} />}
              {activeTab === "rejected" && <RejectedTasksTab id={user._id} />}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
