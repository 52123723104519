import { Grid } from "@mui/material";
import Paper from "../Paper";
import Description from "./Contents/Description";
import { IDocument } from "../../Utils/Structures";
import Attachments from "./Contents/Attachments";
import Links from "./Contents/Links";
import Contributors from "./Contents/Contributors";
import Header from "./Components/Header";
import Names from "./Contents/Names";
import Location from "./Contents/Location";

interface IProps {
  record: IDocument;
  showState?: boolean;
  navigateToCase?: boolean;
}

export default function DocumentCard({
  record,
  showState = false,
  navigateToCase,
}: IProps) {
  return (
    <Paper>
      <Grid container>
        <Header record={record} />
        <Attachments record={record} />
        <Description record={record} />
        {record.names.length > 0 && <Names record={record} />}
        {record.links.length > 0 && <Links record={record} />}
        {record.locations.length > 0 && <Location record={record} />}
        <Contributors record={record} />
      </Grid>
    </Paper>
  );
}
