import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { FirebaseApp, initializeApp } from "firebase/app";

export interface IFirebaseContext {
  app?: FirebaseApp;
}

export const FirebaseContext = createContext<IFirebaseContext>({});

interface IProps {
  children: ReactElement;
}

export function FirebaseContextProvider({ children }: IProps) {
  const [app, setApp] = useState<FirebaseApp>();
  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyBzljpDrWQq8S8shz7GurtwfWE5GXT6C_E",
      authDomain: "note-collection-ymr-all.firebaseapp.com",
      projectId: "note-collection-ymr-all",
      storageBucket: "note-collection-ymr-all.appspot.com",
      messagingSenderId: "608463513548",
      appId: "1:608463513548:web:d78f1146094303c0745f69",
      measurementId: "G-L2PK7RQ4TW",
    };
    setApp(initializeApp(firebaseConfig));
  }, []);
  return (
    <FirebaseContext.Provider value={{ app }}>
      {children}
    </FirebaseContext.Provider>
  );
}

export function useFirebaseApp() {
  const { app } = useContext(FirebaseContext);
  return app;
}
