import { Grid, Typography } from "@mui/material";

interface IProps {
  children: JSX.Element | string;
  lang?: string;
  variant?:
    | "body2"
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "overline"
    | "inherit";
}
export default function Description({
  children,
  variant,
  lang = "en",
}: IProps) {
  return (
    <Grid
      item
      xs={12}
      style={{
        direction: lang === "fa" ? "rtl" : "ltr",
      }}
    >
      <Typography variant={variant}>{children}</Typography>
    </Grid>
  );
}
