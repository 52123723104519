import { Axios } from "axios";
import { ICase, ICollection } from "../Utils/Structures";

interface ISearchCaseParams {
  fullName?: string;
  tags?: string[];
  states?: string[];
  page?: number;
  pageSize?: number;
}
export function searchCases(params: ISearchCaseParams, axios: Axios) {
  return axios
    .post<ICollection<ICase>>("/cases-search", params)
    .then((response) => response.data);
}

interface ICaseDataParams {
  title_fa: string;
  title_en: string;
  description_fa: string;
  description_en: string;
  tags: string[];
  state: string;
  documents: string[];
  requests: string[];
}

export function createCase(params: ICaseDataParams, axios: Axios) {
  return axios
    .post<ICase>("/cases-create", params)
    .then((response) => response.data);
}

export function editCase(id: string, params: ICaseDataParams, axios: Axios) {
  return axios
    .post<ICase>("/cases-update", { ...params, id })
    .then((response) => response.data);
}

export function removeCase(id: string, axios: Axios) {
  return axios.post("/cases-remove", { id }).then((response) => response.data);
}

export function getCase(id: string, axios: Axios): Promise<ICase> {
  return axios
    .post<ICase>("/cases-get", { id })
    .then((response) => response.data);
}
