import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { updateContributor } from "../../../../API/auth";
import { useAxios } from "../../../../Providers/AuthProvider";
import { IUser } from "../../../../Utils/Structures";

interface IProps {
  user: IUser;
  refetch: () => void;
  onClose: () => void;
  show: boolean;
}
export default function InActivateModal({
  user,
  onClose,
  refetch,
  show,
}: IProps) {
  const axios = useAxios();
  const [submitting, setSubmitting] = useState(false);
  const queryClient = useQueryClient();
  async function submit() {
    try {
      setSubmitting(true);
      await updateContributor({ id: user._id, isActive: false }, axios);
      onClose();
      queryClient.refetchQueries({ queryKey: "manage-contributors" });
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  }
  return (
    <Dialog open={show} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>InActivating contributor {user.nickname}</DialogTitle>
      <DialogContent dividers>
        <Typography>
          are you sure you want to inactive this contributor?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={submitting}
          onClick={onClose}
        >
          No
        </Button>
        <Button
          size="small"
          variant="contained"
          color="warning"
          disabled={submitting}
          onClick={submit}
        >
          InActivate
        </Button>
      </DialogActions>
    </Dialog>
  );
}
