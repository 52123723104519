import { Grid, Alert } from "@mui/material";
import { useQuery } from "react-query";
import { useAxios } from "../../../Providers/AuthProvider";
import { Loading } from "../../../Components/Loading";
import { MessageBox } from "../../../Components/MessageBox";
import { pageSize } from "../../../config";
import { useState } from "react";
import Pagination from "../../../Components/Pagination";
import { searchDocuments } from "../../../API/documents";
import DocumentCard from "../../../Components/DocumentCard/DocumentCard";

interface IProps {
  caseId: string;
}
export default function PendingDocumentsTab({ caseId }: IProps) {
  const axios = useAxios();
  const [page, setPage] = useState(1);
  const { isLoading, isError, data, isSuccess, refetch, isRefetching } =
    useQuery(["fetchCaseDocumentsPending", caseId], async () => {
      const result = await searchDocuments(
        { state: "pending", caseId, page, pageSize },
        axios
      );
      return result;
    });
  if (isError) {
    <MessageBox>An error occured, please try again later.</MessageBox>;
  }
  if (isRefetching || isLoading || !data) {
    return <Loading>loading requests</Loading>;
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Alert severity="info">
          Pending documents requires regulators approve. please be patient
          untill it's processed. in case your document declined, it'll move to
          declined tabs, please read the rejection reason there. you document
          may be duplicated, in that case, if your document had any new info,
          it'll be merge with the existing documents and you will be registered
          as co-contributors of that document.
        </Alert>
      </Grid>
      {data.meta.count === 0 && isSuccess && (
        <MessageBox>No documents found.</MessageBox>
      )}
      {data.meta.count > 0 &&
        data.data.map((item) => (
          <Grid item xs={12} key={item._id}>
            <DocumentCard record={item} />
          </Grid>
        ))}
      {data.meta.count > pageSize && (
        <Grid item xs={12}>
          <Pagination
            page={page}
            onChange={(value) => {
              setPage(value);
              setTimeout(() => {
                refetch();
              }, 0);
            }}
            count={Math.ceil(data.meta.count / pageSize)}
          />
        </Grid>
      )}
    </Grid>
  );
}
