import Chip from "@mui/material/Chip";
import FaceIcon from "@mui/icons-material/Face";
import { IReference } from "../Utils/Structures";
import { useContext } from "react";
import { AuthContext } from "../Providers/AuthProvider";
import { useNavigate } from "react-router-dom";
interface IProps {
  user: IReference;
}
export default function ContributorBadge({ user }: IProps) {
  const { profile } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <div
      style={{ marginRight: 8 }}
      onClick={() => {
        navigate("/contributor/" + user._id);
      }}
    >
      <Chip
        size="small"
        icon={<FaceIcon />}
        color="info"
        label={user.name}
        style={{ cursor: "pointer" }}
        variant={profile?._id === user._id ? "filled" : "outlined"}
      />
    </div>
  );
}
