import React, { useContext, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { ITask } from "../../Utils/Structures";
import { Tweet } from "react-twitter-widgets";
import Paper from "../Paper";
import { verifyTweetLink } from "../../API/tasks";
import { useAxios } from "../../Providers/AuthProvider";
import { ToastContext } from "../../Providers/ToastProvider";

interface IProps {
  task: ITask;
  finished: () => void;
}
export default function TweetLink({ task, finished }: IProps) {
  const { showToast } = useContext(ToastContext);
  const axios = useAxios();
  const tweetId = (task.source || "").split("/")[5];
  const [submitting, setSubmitting] = useState(false);
  async function submit(acceptState: string) {
    try {
      setSubmitting(true);
      verifyTweetLink(task._id, acceptState, axios);
      finished();
      showToast("tweet verified! thank you.");
    } catch (error) {
      console.log(error);
      showToast("an internal error happened");
    } finally {
      setSubmitting(false);
    }
  }
  return (
    <Paper>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Tweet tweetId={tweetId} options={{ conversation: "none" }} />
        </Grid>
        <Grid item xs={12} md={6} style={{ marginTop: 8 }}>
          <Grid container>
            <Grid item xs={12}>
              <ul>
                <li>
                  <Typography variant="body1">
                    tweets as reply to thank someone is not valid as a document.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    retweets of other tweets (no mather how the source tweet
                    verifies as document) is not a document.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    tweets to informing states or beliefs of social profile are
                    not document.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    Arguing and talking normally with others is not considered a
                    document.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    Chanting, publishing hashtags, text that does not have any
                    useful information is not considered a document.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    Even if the publication is false, it is considered a
                    document. Leave it to time to judge.
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Is this tweet considered as a document?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="success"
                variant="outlined"
                fullWidth
                disabled={submitting}
                onClick={() => {
                  submit("accepted");
                }}
              >
                Yes, it's a document
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="warning"
                variant="outlined"
                fullWidth
                disabled={submitting}
                onClick={() => {
                  submit("rejected");
                }}
              >
                No, it's not a document
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
