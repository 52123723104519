import { Axios } from "axios";
import { ICollection, IUser } from "../Utils/Structures";

export interface IAuthResponse {
  _id: string;
  nickname: string;
  contributionPoints: number;
  role: string;
  username: string;

  isActive: boolean;
  accessToken: string;
  expiresAt: number;

  createdAt: number;
  updatedAt: number;
}
export function login(username: string, password: string, axios: Axios) {
  return axios
    .post<IAuthResponse>("/auth-login", { username, password })
    .then((response) => response.data);
}

export function logout(axios: Axios) {
  return axios.post("/auth-logout").then(() => true);
}

export function getProfile(axios: Axios) {
  return axios
    .post<IAuthResponse>("/auth-getProfile")
    .then((response) => response.data);
}

interface IProfileParam {
  username?: string;
  password?: string;
  nickname?: string;
  contact?: string;
}
export function updateProfile(params: IProfileParam, axios: Axios) {
  return axios
    .post<IUser>("/auth-updateProfile", params)
    .then((response) => response.data);
}
interface ISearchParams {
  nickname?: string;
  username?: string;
  isActive?: string;
  id?: string;
  page: number;
  pageSize: number;
}
export function searchUsers(params: ISearchParams, axios: Axios) {
  return axios
    .post<ICollection<IUser>>("/auth-search", params)
    .then((response) => response.data);
}

interface IRegisterParam {
  username: string;
  password: string;
  nickname: string;
}

export function register(params: IRegisterParam, axios: Axios) {
  return axios
    .post<IAuthResponse>("/auth-register", params)
    .then((response) => response.data);
}

interface ILeaderBoardResponse {
  data: {
    _id: string;
    nickname: string;
    contributionPoints: number;
    role: string;
  }[];
  meta: {
    page: number;
    pageSize: number;
    count: number;
  };
}
export function getLeaderboard(page: number, axios: Axios) {
  return axios
    .post<ILeaderBoardResponse>("/auth-leaderboard", { page })
    .then((response) => response.data);
}

interface IUpdateContributorParams {
  id: string;
  isActive?: boolean;
}

export function updateContributor(
  params: IUpdateContributorParams,
  axios: Axios
) {
  return axios
    .post<IUser>("/auth-updateContributor", params)
    .then((response) => response.data);
}
