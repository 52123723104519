import MPagination from "@mui/material/Pagination";

interface IProps {
  page: number;
  onChange: (value: number) => void;
  count: number;
}
export default function Pagination({ page, onChange, count }: IProps) {
  return (
    <MPagination
      page={page}
      onChange={(event, value) => {
        onChange(value);
      }}
      count={count}
    />
  );
}
